import { ModuleModel } from "../../models/Module.model";
import { Section, getName } from "../Section/Section";
import "./Module.css";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ElementModel, ElementType } from "../../models/Element.model";
import { TitleModuleTextField } from "../TitleModuleTextField/TitleModuleTextField";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ModuleService } from "../../service/program/Module.service";
import { EmptyModule } from "../EmptyModule/EmptyModule";
import {
  ModuleSelected,
  SkillSelected,
  TypeContext,
} from "../../context/type.context";
import { useDrag, useDragLayer, useDrop } from "react-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeletionAlert from "../DeletionAlert/DeletionAlert";
import { RoleType, UserContext } from "../../context/user.context";
import { DisplayHourContext } from "../../context/displayleHour.context";
import { notify, NotificationType } from "../../utils/toaster";
import { ModulesContext } from "../../context/element.context";
import { GlobalDragContext } from "../../context/globalDrag.context";
import { DebugContext } from "../../context/debugContext";


import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { ElementService } from "../../service/program/Element.service";


export function Module(props: {
  firstLevel: boolean;
  module: ModuleModel;
  parentModule?: string;
  modules: ModuleModel[];
  setModules: Dispatch<SetStateAction<ModuleModel[]>>;
  index: number;
  swapObject?: (
    item: ModuleModel,
    item2: ModuleModel,
    /* container: ModuleModel[] */
    requestApi:boolean
  ) => void;
  modules_firstLevel: ModuleModel[];
}): JSX.Element {
  
  const [debug, ] = useContext(DebugContext);

  const sortSubModules = (subModules:ModuleModel[]) => {
    return subModules.sort((a, b) => {
       return a.number - b.number;
     })      
  }

  const [module, setModule] = useState(props.module);
  const [subModules, setSubModules] = useState( sortSubModules(props.module.modules) );
  const [finalDropModules, setFinalDropModules] = useState<ModuleModel[]>([] );

  const [displayHour] = useContext(DisplayHourContext);
  const [skills, setSkills] = useState(props.module.skills);
  
  const [selectedType, setSelectedType] = useContext(TypeContext);
  const [user] = useContext(UserContext);
  
  const [, setModulesContext] = useContext(ModulesContext)
  const isMounted = useRef(false);

  
  const [globalDrag, setGlobalDrag ] = useContext(GlobalDragContext);

  const log = (...args:any[]) => {
    if(debug.config?.debug){
      console.log(...args)
    }
  }

  useEffect(() => {
    // when module props have changed update current state
    setModule(props.module)
  }, [props.module])
  

  useEffect(() => {


     if(isMounted.current){
      
      // this module have changed
      
       setModulesContext((prevState) => {

        log("the current module have changed, update global modules context", module._id,  module)

        if(props.firstLevel){
          
          // it is a first module level
            
           if(module._id.length > 5){
            
            // the module is ever stored at DB

            prevState.forEach((m) => {
              
               if(m._id === module._id){                
                m = module
               }else{
               
                if(m._id == module.tmpId){
                  // replace the temporary data for fresh data coming from DB (real mongodb id)
                  m._id = module._id
                  m.title = module.title
                }
               }


             });
           }else{
            // it's an 'add' operation
            let newModule = prevState.find((m:ModuleModel) => m._id == module.tmpId)
            if(newModule){
              setModule(newModule)
            }
           }

         }
         else{
           prevState.forEach((m) => {

             if(m._id === props.parentModule){
               
              // we speak about a submodule

               const isNew = !m.modules.some((subModule) => {
                 return subModule._id === module._id
               });

               if(isNew){
                // submodule is a new one
                
                 m.modules = m.modules.filter((subModule) => {
                  return subModule._id.length > 5
                })
                
                let isInState:boolean = prevState.find((m:ModuleModel) => m._id == module._id) != undefined

                if(isInState == false){
                  // if not in state, add it
                  m.modules.push(module)
                  let newModules = [...m.modules]                  
                  props.setModules(newModules)
                  setSubModules(newModules)
                  setModule(module)
                }else{
                  props.setModules(m.modules)
                }
              }
              else {
                // the submodule is an existing one
                m.modules.forEach((subModule, index, array) => {
                  if (subModule._id === module._id) {
                    subModule.title = module.title
                    subModule.skills = skills 
                  }
                })
              }

            }
          })
        }

        return [...prevState]
      })

    }
    else {
      isMounted.current = true;
    }
  }, [module, skills])

   useEffect(() => {         
      // side effect of changing submodules => refresh the proper part of the global context
      module.modules = [...subModules]
      setModule(module)   
   }, [subModules])

  const handleDelete = (deletedModule: ModuleModel, index: number) => {

    if (deletedModule._id.length > 5) {
      const setContentModules = props.setModules as Dispatch<SetStateAction<(ModuleModel | ElementModel)[]>>;
      new DeletionAlert(props.module, setContentModules, index, props.firstLevel);
    }

    if(props.firstLevel == true){
      // first level delete
      props.setModules((previousState) => {
        let newSubmodules = [...previousState];
        newSubmodules = previousState.filter((m:ModuleModel) => m._id != deletedModule._id)
        return newSubmodules;
      }); 
    }else{
      // second level delete      
      props.setModules((previousState) => {
        let newSubmodules = [...previousState];
        newSubmodules = previousState.filter((m:ModuleModel) => m._id != deletedModule._id)
        return newSubmodules;
      }); 
    }

 

  };

  const ErrorDisplay = useCallback((err: any) => {
    switch (err.status) {
      case 500:
        notify(NotificationType.ERROR, err.data.error || err.statusText);
        break;
      case 400:
        notify(NotificationType.ERROR, err.data.error || err.statusText);
        break;
      default:
        notify(NotificationType.ERROR, err.statusText);
    }
  }, []);

  const add = () => {
    const id = subModules.length;
    let newModule = new ModuleModel(
      id.toString(),
      "",
      [],
      [],
      null,
      null,
      subModules.length + 1
    );

    newModule.setTmpId(newModule._id)
    
     setSubModules((previousState) => {
      return [...previousState, newModule]
    }); 

    
    
  };
  const addModuleFromSugeestionBar = (module: ModuleModel) => {
    ModuleService.addModuleInModuleFromSuggestion(module._id, props.module._id, module.origin)
      .then((module) => {
        setSubModules((previousState) => {
          return [...previousState, module];
        });
      })
      .catch(ErrorDisplay);
  };

  const handleFocus = () => {
    if (props.firstLevel && skills.length === 0) {
      module.shouldFocus = false
      setSelectedType(
        new ModuleSelected(props.firstLevel, module._id, subModules, setSubModules)
      );
    } else {
      setSelectedType(new SkillSelected(module._id, skills, setSkills));
    }
  };

  const getInfo = (module: ModuleModel) => {
    if (module.writer) {
      return (
        "Ce module a été rédigé par " +
        module.writer.firstname +
        " " +
        module.writer.lastname
      );
    } else {
      return "Ce module a été rédigé par Matchers";
    }
  };


  const saveModuleOrder = (copy:ModuleModel[], moduleParentId:string) => {          
      ModuleService.updateModuleOrderInModule(copy, moduleParentId)
        .then(() => {
          setSavedModules(copy);
        })
        .catch((e) => {
          ErrorDisplay(e);
          setSubModules(savedModules);
        });


  }

  const swapSubmoduleObject = useCallback(
    
    (item: ModuleModel, item2: ModuleModel,  container:ModuleModel[] ) => {

      if(debug.config?.debug){
        console.log("swapSubmoduleObject() SUBMODULES", container.map((d) => d._id))
        console.log("swap", item.title, " <-> ", item2.title)
      }
      
      let copy = [...container];
      
      const index = copy.findIndex((m:ModuleModel) => m._id == item._id)
      const index2 = copy.findIndex((m:ModuleModel) => m._id == item2._id)

      if(debug.config?.debug){
        console.log(`index of item   -----------> ${index} item ${item}`)
        console.log(`index of item2  -----------> ${index2} item ${item2}`)
      }

      if (index !== -1 && index2 !== -1) {
        copy[index] = item2.getClone()
        copy[index].number = item.number

        copy[index2] = item.getClone()
        copy[index2].number = item2.number

        if(debug.config?.debug){
          console.log("[swap] submodule result", copy )
          console.log("[swap] IS DRAG DROP ACTIVE :", isDraggingSwap  )
        }
            
          props.setModules(copy)

          // set the new order at DB
          saveModuleOrder(copy, props.parentModule as string)          

      }else{
        console.error("swap index == -1 [index=", index, " => ", item.title , "] [index2=", index2, item2.title, "]")
      }
    },
    [ subModules, module._id, ErrorDisplay]
  );


  // when dropping module from suggestions
  const [{suggestionItem, isOverSuggestion, canDropSuggestion, suggestionIsOverCurrent }, dropSuggestionModule] = useDrop(() => ({
    accept: ElementType.MODULE,
    drop: (item: ModuleModel) => {
      
      if(!suggestionIsOverCurrent){
        if(debug.config?.debug){
          console.log("addModuleFromSugeestionBar module")
        }
        addModuleFromSugeestionBar(item);
      }
      globalDrag.suggestionCardIsDragging = false;
      setGlobalDrag(globalDrag)
    },
    collect: (monitor) => ({      
      suggestionItem: monitor.getItem() ,
      canDropSuggestion: monitor.canDrop(),
      isOverSuggestion: monitor.isOver(),
      suggestionIsOverCurrent: monitor.isOver({ shallow: true }),
    }),
  }));

  let dropSuggestionClass = "";
  if (suggestionIsOverCurrent && !(props.firstLevel && props.module.skills.length > 0)) {
    dropSuggestionClass = "drop-btn drop-btn-hover ";
  } else if (canDropSuggestion && !(props.firstLevel && props.module.skills.length > 0)) {
    dropSuggestionClass = "drop-btn  drop-btn-waiting";
  }


    // when dragging a module or submodule
    const [{isDraggingSwap, isDragginSwapFirstLevel} , dragSwapNode, preview] = useDrag(
      () => ({
        type: props.firstLevel ? "firstLevel" : "secondLevel",
        item: module,
        
        collect: (monitor) => {                   
          
          return {
            droppedItem: monitor.getItem(),
            isDraggingSwap: monitor.isDragging(),
            isDragginSwapFirstLevel: props.firstLevel
            
          };
        },
        end: (item, monitor) => {

          // a drop occured
          if(monitor.didDrop() == true){
                       
          }
          
          // disable global drag context
          globalDrag.moduleFirstLevelSwapIsDragging = false  
          setGlobalDrag(globalDrag)    
        },
      }),
      [props.module, props.parentModule]
    );
  

  // when reordering module
  const [{isOverSwap, canDropSwap}, dropInternalModule] = useDrop(
    () => ({
      accept: props.firstLevel ? "firstLevel" : "secondLevel",
      hover(item: ModuleModel, monitor) {   

        if (item._id !== module._id) {
          if(debug.config?.debug){
            console.log("[module] monitor getItem on hover", monitor.getItem().title)
            console.log("[module] swap [useDrop()] [hover] --> swap object ", item.title, props.module.title)
            console.log("[module] swap [hover] props level", props.firstLevel ? "firstLevel" : "secondLevel")
            console.log("[module] swap [hover] swap ", item.title, " <------------> ", module.title)
            console.log("[module] swap swapObject function: ", props.swapObject)
          }
         }
      },
      canDrop: (item:ModuleModel, monitor:any) => {
        return props.modules.map((i:ModuleModel) => i._id).includes(monitor.getItem()._id) 
      },
      collect: (  monitor) => ({      
        canDropSwap: monitor.canDrop() && props.modules.map((i:ModuleModel) => i._id).includes(monitor.getItem()._id),      
        isOverSwap: monitor.isOver({ shallow: true }) && props.modules.map((i:ModuleModel) => i._id).includes(monitor.getItem()._id)                  
      }),
      drop: (item: ModuleModel, monitor) => {
        
        if (monitor.canDrop() == true) {                  
          if(props.firstLevel == false){
            swapSubmoduleObject(monitor.getItem(), props.module , props.modules )
          }else{
            if(props.swapObject){
              props.swapObject(monitor.getItem(), props.module, true)
            }
          }                              
        }
      }
    }), [ props.modules, props.module, swapSubmoduleObject, subModules]
  );

  /*
     prevent other modules that a first level modul dragging is occuring 
     kind of 'first drag event'
  */
  useEffect(() => {        
    if (isDraggingSwap == true) {
      
      if(props.firstLevel == true){
        // scroll to the first module (because window.height have changed due to hidding of submodules & skills)
        setTimeout(() => {
          const element = document.getElementById("module-container");
          if(element){
          window.scrollTo({
            top: element.offsetTop - 90, // 90 is header size
            behavior: "smooth"
          });
        }
        }, 50);
      }
      

      globalDrag.moduleFirstLevelSwapIsDragging = isDragginSwapFirstLevel        
    }else{
      globalDrag.moduleFirstLevelSwapIsDragging = false    
    }    
    setGlobalDrag({...globalDrag})    
  }, [isDragginSwapFirstLevel,isDraggingSwap])
  

  

  const [savedModules, setSavedModules] = useState(subModules);
  
  const [duration, setDuration] = useState(
    module.duration ? module.duration : 0
  );

  let moduleCanDropClass = isOverSwap == true ?  "module-can-swap-drop" : ""

 

  const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDuration(parseInt(event.currentTarget.value));
  };

  const updateDuration = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log(event.currentTarget.value)
    if (module._id.length > 5) {
      ModuleService.updateModuleDuration(
        module._id,
        parseInt(event.currentTarget.value)
      );
    }
  };


  const renderAddModuleOrDropZone = (str:string, type:ElementType) => {
    return(
      <>
      { globalDrag.suggestionCardIsDragging == false  &&
        <span>+ Ajouter <span className="bold">{str}</span></span>
      }

      { globalDrag.suggestionCardIsDragging == true && suggestionItem?.getType() == type  &&
        <div style={{flexDirection: "column", alignItems: "center", alignContent: "center",  display: "flex"}}>
          {suggestionIsOverCurrent == false ? <HighlightAltIcon></HighlightAltIcon> : <DoneOutlineIcon></DoneOutlineIcon>}
          
        <div className="target-drop-module">
          <span style={{marginTop: 10}}>Faites glisser pour ajouter en tant que sous module de</span>
          <span className="bold">{props.module.title}</span>
        </div>

        </div>
      }
    </>
    )
  }

  if (props.firstLevel) {
    return (
      <div ref={(node) => {
        preview(dragSwapNode(node));
      }}
      >
      { debug.config?.debugDom && <small style={{display: "block", marginTop: 40}}>{"⏬ moduleId: " + props.module._id} {"skills " + skills?.length} </small> }
        <div  id="module" className={`flex-container module ${moduleCanDropClass}`} ref={dropInternalModule}>
          <div className="delete">
            <IconButton
              aria-label="delete"
              size="small"
              id={(module && module?._id?.length > 5) ? module._id : props.index.toString()}
              onClick={() => {
                handleDelete(module, props.index);
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
          
          <div
            className="grid-container delete grab-cursor"
            ref={(node) => {
              dragSwapNode(node);
            }}
          >
            <DragIndicatorIcon
              sx={{
                "&.MuiSvgIcon-root": {
                  margin: "auto",
                },
              }}
            />
          </div>


          <h3 className="flex-none titre2">
            {`Module ${props.index + 1} :`}&ensp;{" "}
          </h3>{" "}

          
          <TitleModuleTextField
            firstLevel={props.firstLevel}
            placeholder={"Veuillez nommer le module en premier lieu"}
            size={2}
            module={module}
            setModule={setModule}
            handleFocus={handleFocus}
          />
        
        </div>


        {module._id.length > 5 && (
          <div className="alinea3">
            {displayHour ? (
              <TextField
                hidden={displayHour}
                sx={{ width: "140px" }}
                type="number"
                label={""}
                value={duration}
                inputProps={{ min: "0" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Durée</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">h</InputAdornment>
                  ),
                }}
                size="small"
                onChange={handleDurationChange}
                onBlur={updateDuration}
              ></TextField>
            ) : null}
          </div>
        )}
        {subModules.length > 0 && (
          <div
            className={
              globalDrag.suggestionCardIsDragging == true 
                ? "border-active alinea"
                : "border alinea"
            }
          >
            {/* 
              SUBMODULES
              (hide submodules in internal root modules drag and drops)
            */}
            { globalDrag.moduleFirstLevelSwapIsDragging == false && subModules.map((m, index) => {
              return (
                <div key={m._id} >
                  
                  <Module
                    firstLevel={false}
                    module={m}
                    modules_firstLevel={props.modules}
                    modules={subModules}
                    parentModule={props.parentModule}
                    setModules={setSubModules}
                    index={index}
                    />
                </div>
              );
            })}
            
            {/* {selectedType instanceof ModuleSelected &&
              selectedType.id === module._id && (
                <div className="alinea2 help-add-module">
                  Cliquez sur une suggestion et ajoutez un sous-module à ce
                  module
                </div>
              )}
 */}

          {/* ADD SUBMODULE BTN OR DROP ZONE */}
            { (suggestionItem == undefined || (globalDrag.suggestionCardIsDragging == true &&  suggestionItem?.modules?.length == 0 )) &&
              <button 
              ref={(node) => {
                props.firstLevel && props.module.skills.length > 0
                  ? preview(node)
                  : preview(dropSuggestionModule(node));
              }}
              
              className={`alinea ${dropSuggestionClass}`} id="add-module" onClick={add}>              
                  { renderAddModuleOrDropZone("sous " + getName(ElementType.MODULE), ElementType.MODULE) }
              </button>
            }


          </div>
        )}
        {skills.length > 0 &&  globalDrag.moduleFirstLevelSwapIsDragging == false  && (
          <div>
            <Section
              content={skills}
              title={""}
              path={[props.index]}
              type={ElementType.SKILL}
              parentModule={module._id}
              setContent={(skills) => {
                setSkills(skills) }}
            />
          </div>
        )}

        {/* empty submodules && skills  */}
        {subModules.length === 0 && skills.length === 0 && module._id.length > 5 && (
          <div>
            <EmptyModule 
            module={props.module}
            addModuleFromSuggestion={addModuleFromSugeestionBar}
            addCompetenceFromSuggestion={(skill:ElementModel) => {
             
              if(props.parentModule){       
                ElementService.addSkill(props.parentModule, skill.text, skill._id)
                .then((skill) => {                  
                   module.skills.push(skill)
                   setSkills([...module.skills]) 
                })
                .catch(ErrorDisplay);
            }

            }}
            setModules={setSubModules} setSkills={setSkills} />
          </div>
        )}

        
      </div>
    );
  } else { // secondLevel
    return (
      <div
         ref={(node) => {
          preview(dropInternalModule(node));
          preview(dragSwapNode(node))
        }} 
      >
        {module._id.length < 5 ||
        (user !== null &&
          (user.role === RoleType.ADMIN ||
            user.role === RoleType.MAIN_TRAINER ||
            (user.role === RoleType.TRAINER &&
              user.user !== null &&
              module.writer !== null &&
              user.user._id === module.writer._id))) ? (
          <div>            

            { debug.config?.debugDom && <div style={{marginTop: 20}}>
            <small>⏬ subModuleId: {module._id} parentModuleId: {props.parentModule} name: {props.module.title}  skills: {skills?.length}</small>
            </div>
            }

            <div className={`flex-container module ${moduleCanDropClass}`}>              
              <div className="delete">
                <IconButton
                  aria-label="delete"
                  size="small"
                  id={
                    module._id.length > 5 ? module._id : props.index.toString()
                  }
                  onClick={() => {
                    handleDelete(module, props.index);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>


              <div
                className="grid-container delete grab-cursor"
                ref={(node) => {    }}
              >
                <DragIndicatorIcon
                  sx={{
                    "&.MuiSvgIcon-root": {
                      margin: "auto",
                    },
                  }}
                />
              </div>
                                          
              <h3 className="flex-none">{` ${props.index + 1} - `}</h3>               
              <TitleModuleTextField
                firstLevel={props.firstLevel}
                placeholder={""}
                size={2}
                module={module}
                parentModuleId={props.parentModule}
                setModule={setModule}
                handleFocus={handleFocus}
              />
              
            </div>
            <div>              
              <Section
                content={skills}
                title={""}
                path={[props.index]}
                type={ElementType.SKILL}
                parentModule={module._id}
                setContent={(s:SetStateAction<ElementModel[]>) => {
                  setSkills(s)
                }}
              />
            </div>
          </div>
        ) : (
          <div>
            <Tooltip title={getInfo(module)} followCursor>
              <div className="alinea2">
                <h3>{` ${props.index + 1} - ${module.title}`} </h3>
                <div className="alinea2">
                  {module.skills.map((skill) => {
                    return (
                      <li key={skill._id}>
                        <label>{skill.text}</label>
                      </li>
                    );
                  })}
                  {module.skills.length === 0 && (
                    <p className="alinea2 no-element">Aucun élément</p>
                  )}
                </div>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}
