import { ProgramModel, OPCO, ProgramStatus } from "../../../models/Program.model";
import Headers from "../../../Component/Headers/Headers";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import SuggestionField from "../../../Component/SuggestionField/SuggestionField";
import { ActionButton } from "../../../Component/ActionButton/ActionButton";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import { ProgramService } from "../../../service/program/Program.service";
import { HoursTextField } from "../../../Component/HoursTextField/HoursTextField";
import { Status } from "../../../Component/Status/Status";
import { Program } from "../../../Component/Program/Program";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect
} from "react";
import { ProgramContext } from "../../../context/program.context";
import { PdfService } from "../../../service/pdf/Pdf.service";
import { DisplayHourContext } from "../../../context/displayleHour.context";
import { BootstrapTooltip } from "../../../Component/Tooltip/BootstrapTooltip";
import { Info } from "@mui/icons-material";
import ConfirmationDialog from "../../../Component/ConfirmationDialog/ConfirmationDialog";
import { RoleType, UserContext } from "../../../context/user.context";
import { notify, NotificationType } from "../../../utils/toaster";
import { toast } from "react-toastify";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


const TOOLTIP_ALL_TRAINERS_MUST_VALIDATE = "Tous les formateurs doivent avoir validé le programme avant de le soumettre au client"
const TOOLTIP_MINIMUM_MODULES_REQUIRED = "Vous devez créer le nombre minimum de module demandé selon le nombre d'heure pour la formation."

export default function ProgramInEdition(props: {
  program: ProgramModel;
  setProgram: Dispatch<SetStateAction<ProgramModel | null>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  displayProgram: boolean | null;
  setDisplayProgram: Dispatch<SetStateAction<boolean | null>>;
}) {
  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false);
  const [openSuggestion, setOpenSuggestion] = React.useState(true);
  const [program, setProgram] = useContext(ProgramContext);
  const [displayHours, setDisplayHours] = useState(
    props.program.opco === OPCO.AKTO ? true : false
  );


  useEffect(() => {
    console.log("open confirmaiton dialog", confirmationDialogOpened)
  }, [confirmationDialogOpened])


  const createPDF = () => {
    ProgramService.getProgram().then((returnProgram: ProgramModel) => {
      PdfService.generatePdf(returnProgram).save(returnProgram.title + ".pdf");
    })
  };
  const [user] = useContext(UserContext);

  const editProgramAdmin = () => {
    ProgramService.editProgramAdmin()
      .then((program) => {
        setProgram(program);
        notify(NotificationType.INFO, "Actualiser la page pour voir les modifications.");
        window.location.reload();
      })
      .catch((e) => {
        switch (e.response.status) {
          case 400:
            notify(NotificationType.ERROR, e.data.data);
            break;
          case 403:
            notify(NotificationType.ERROR, "Vous n'avez pas les droits appropriés");
            break;
          default:
            notify(NotificationType.ERROR, e.response.data);
        }
      });
  };

 
 
 /* display download pdf button only for admins  */
 const renderDownloadPdf = () => {
  if(user && user.role === RoleType.ADMIN && program){
    return(
      <ActionButton
      label={"download"}
      text={"Télécharger"}
      icon={PictureAsPdfIcon}
      onClick={() => {
        ProgramService.generatePdfAtBackend(program?._id).then((r: any) => {
          var file = new Blob([r], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
      } }
      /> 
      )
    }
    
    return(null)
    
  }


  const renderNextStateActionButton = () => {

    let tooltipTitle = props.program.skipCollaboration == true ?  TOOLTIP_MINIMUM_MODULES_REQUIRED : TOOLTIP_ALL_TRAINERS_MUST_VALIDATE
  
    return (
      <>
        <Tooltip
          disableInteractive={
            program ? program.notValidated.length < 0 : true
          }
          title={ tooltipTitle }
          followCursor
        >
          <div>
            {user && user.role === RoleType.MAIN_TRAINER ? (
              <ActionButton
              label={"submit"}
              text={"Soumettre"}
              icon={SendIcon}
              onClick={() => setConfirmationDialogOpened(true)}
              disable={
                program ? program.notValidated.length > 0 : true
              }
            />
            ) : (
              <ActionButton
                label={"edit"}
                text={"Visualisation"}
                icon={SendIcon}
                onClick={editProgramAdmin}
                disable={
                  program ? program.notValidated.length > 0 : true
                }
              />
            )}
          </div>

        </Tooltip>

      </>
    )

  }

  return (
    <DisplayHourContext.Provider value={[displayHours, setDisplayHours]}>
      <div>
        <div>
          <Headers showSave={true} status={ProgramStatus.EDITION} />

          <SuggestionField
              open={openSuggestion}
              setOpen={setOpenSuggestion}
            />

      <div style={{ marginLeft: (openSuggestion ? "350px" : "0")}}>
          <h1 style={{
           
            marginTop: "130px",
            textAlign: "center",
            padding: "0",
          }}>Édition de programme</h1>
          <Box
            sx={{
              display: "flex",
            }}
            style={{marginLeft: 20}}
          >
          
            <Box sx={{ width: "100%" }}>
              {/*  ---- TOP BUTTONS ----  */}
              <Grid container alignItems="center">
                {/* ---- STATUS AND NB HOURS OF THE PROGRAM ---- */}
                <Stack direction="row" alignItems="center" spacing={2}>
                  {props.program.opco === OPCO.AKTO ? (
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked={true}
                          value={!displayHours}
                          onChange={() => {
                            setDisplayHours(!displayHours);
                          }}
                        ></Switch>
                      }
                      label={"Afficher les heures"}
                    />
                  ) : null}
                  <HoursTextField hours={props.program.hours} />
                  <Status status={props.program.status} />
                </Stack>

                <Grid item xs />
                  {renderDownloadPdf()}
                  {renderNextStateActionButton()}                 
                <Grid
                  container
                  item
                  xs={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <BootstrapTooltip
                    title={
                      "Il faut au moins 1 module toutes les 10 heures de formation"
                    }
                  >
                    <div>
                      <IconButton
                        color="primary"
                        aria-label="add to shopping cart"
                      >
                        <Info style={{ color: "#D93174" }} />
                      </IconButton>
                    </div>
                  </BootstrapTooltip>
                </Grid>

              </Grid>

              {/* ---- PROGRAM ---- */}
              <Program program={props.program} setProgram={props.setProgram} />
              {/*  ---- BOTTOM BUTTONS ----  */}
              <Stack direction="row">                
                {props.program.skipCollaboration && (
                  <>
                    <Tooltip
                      disableInteractive={
                        program ? program.notValidated.length < 0 : true
                      }
                      title={
                        "Tous les formateurs doivent avoir validé le programme avant de le soumettre au client"
                      }
                      followCursor
                    >
                      <div>
                        {user && user.role === RoleType.MAIN_TRAINER ? (
                          <ActionButton
                            label={"submit"}
                            text={"Soumettre"}
                            icon={SendIcon}
                            onClick={() => setConfirmationDialogOpened(true)}
                            disable={
                              program ? program.notValidated.length > 0 : true
                            }
                          />
                        ) : (
                          <ActionButton
                            label={"edit"}
                            text={"Visualisation"}
                            icon={SendIcon}
                            onClick={editProgramAdmin}
                            disable={
                              program ? program.notValidated.length > 0 : true
                            }
                          />
                        )}
                      </div>
                    </Tooltip>

                  </>
                )}


                <ConfirmationDialog open={confirmationDialogOpened} setOpen={setConfirmationDialogOpened} />

                {props.program.skipCollaboration === false && (
                  <Tooltip
                    disableInteractive={
                      program ? program.notValidated.length < 0 : true
                    }
                    title={
                      "Tout les formateurs doivent avoir validé le programme avant de le soumettre au client"
                    }
                    followCursor
                  >
                    <div>
                      {user && user.role === RoleType.MAIN_TRAINER ? (
                        <ActionButton
                          label={"submit"}
                          text={"Soumettre"}
                          icon={SendIcon}
                          onClick={() => setConfirmationDialogOpened(true)}
                          disable={
                            program ? program.notValidated.length > 0 : true
                          }
                        />
                      ) : (
                        <ActionButton
                          label={"edit"}
                          text={"Visualisation"}
                          icon={SendIcon}
                          onClick={editProgramAdmin}
                          disable={
                            program ? program.notValidated.length > 0 : true
                          }
                        />
                      )}
                    </div>
                  </Tooltip>
                )}       
              </Stack>
            </Box>

            
          </Box>
          </div>



        </div>
      </div>
    </DisplayHourContext.Provider>
  );
}
