import { ProgramModel } from "../../models/Program.model";
import axios from "axios";
import { plainToInstance } from "class-transformer";
import { TrainerModel } from "../../models/Trainer.model";

export class ProgramService {
  public static getProgram(): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/training-program")
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore

          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static getOldPrograms(): Promise<ProgramModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/training-program/oldPrograms")
        .then((response) => {
          let program = response.data;
          let programs = plainToInstance(ProgramModel, program);
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static getSimilarPrograms(): Promise<ProgramModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/training-program/getSimilar")
        .then((response) => {
          let program = response.data;
          let programs = plainToInstance(ProgramModel, program);
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static startProgramFromTemplate(
    templateId: string | null
  ): Promise<ProgramModel> {
    if (templateId) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            process.env.REACT_APP_API_URL + "/training-program/chooseTemplate",
            {
              templateId: templateId,
            }
          )
          .then((response) => {
            let program = response.data.program;
            let programs = plainToInstance(ProgramModel, program);
            // @ts-ignore
            resolve(programs);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            process.env.REACT_APP_API_URL + "/training-program/chooseTemplate"
          )
          .then((response) => {
            let program = response.data.program;
            let programs = plainToInstance(ProgramModel, program);
            // @ts-ignore
            resolve(programs);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  public static updateTitleProgram(title: string): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/updateTitle",
          { title: title }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static updateSubtitleProgram(subtitle: string): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/updateSubtitle",
          { subtitle: subtitle }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          console.log(error.response)
          reject(error);
        });
    });
  }
  public static updateHoursProgram(
    nbHours: number,
    fixed: boolean
  ): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/updateHours",
          { nbHours: nbHours, fixed: fixed }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public static addTrainer(
    trainer: TrainerModel
  ): Promise<{ token: string; userId: string }> {
    return new Promise((resolve, reject) => {
      axios
        .patch<{ token: string; userId: string }>(
          process.env.REACT_APP_API_URL + "/training-program/addTrainer",
          trainer
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static validateProgram(): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/validate")
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

    public static finalization(): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/finalization")
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static moderationValidate(): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/moderationValidate")
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public static moderationInvalidate(comment:string|undefined): Promise<ProgramModel> {

    const params = comment ? {comment: comment} : {};

    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/moderationInvalidate", params  )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public static submitProgramForModeration(
    canBeSuggested: boolean
  ): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/submit",
          { canBeSuggested: canBeSuggested }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  public static editProgramAdmin(): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/editProgramAdmin")
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static clientValidation(comment:string|undefined = undefined): Promise<ProgramModel> {

    const params = comment ? {comment: comment} : {};

    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/clientValidation", params)
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static clientInvalidation(comment:string | undefined): Promise<ProgramModel> {

    const params = comment ? {comment: comment} : {};

    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/clientInvalidation", params)
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static openPdfInTab = (programId: string, programName:string = "programme-Matchers") => {
    ProgramService.generatePdfAtBackend(programId).then((r:any) => {
      var file = new Blob([r], {type: 'application/pdf'});
      var fileURL = URL.createObjectURL(file);
    
      // method 1
      // let newWindow = window.open(fileURL);

      // method 2
      // only method to name the pdf otherwise it opens it in a browser tab but without a defined name (method 1)
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = programName; // Set the desired filename here
      link.style.display = 'none';
      
      document.body.appendChild(link);
      link.click();
      
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
      
  })
  }


  public static generatePdfAtBackend(programId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/training-program/getPdf", {
          responseType: 'blob',
          params: { programId: programId } })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


}
