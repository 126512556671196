import { ProgramModel, ProgramStatus } from "../../models/Program.model";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { RoleType, UserContext } from "../../context/user.context";
import { TemplateChoiceTrainer } from "./TemplateChoice/TemplateChoice";
import SubmitedProgram from "./SubmitedProgram/SubmitedProgram";
import ProgramInEditionTrainer from "./ProgramInEdition/ProgramInEditionTrainer";

export default function TrainerPage(props: {
  program: ProgramModel;
  setProgram: Dispatch<SetStateAction<ProgramModel | null>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  displayProgram: boolean | null;
  setDisplayProgram: Dispatch<SetStateAction<boolean | null>>;
}) {
  const [user] = useContext(UserContext);
  const [validated, setValidated] = useState(
    (props.program !== null &&
      props.program.status !== ProgramStatus.EDITION) ||
      (!(
        user &&
        (user.role === RoleType.MAIN_TRAINER || user.role === RoleType.ADMIN)
      ) &&
        !(
          user &&
          props.program !== null &&
          props.program.notValidated.filter(function (e) {
            return user.user && e._id === user.user._id;
          }).length > 0
        ))
  );
  useEffect(() => {
    setValidated(
      (props.program !== null &&
        props.program.status !== ProgramStatus.EDITION) ||
        (!(
          user &&
          (user.role === RoleType.MAIN_TRAINER || user.role === RoleType.ADMIN)
        ) &&
          !(
            user &&
            props.program !== null &&
            props.program.notValidated.filter(function (e) {
              return user.user && e._id === user.user._id;
            }).length > 0
          ))
    );
  }, [props.program, user]);

  if (
    (props.displayProgram !== null && !props.displayProgram) ||
    props.program.status === ProgramStatus.NOT_STARTED
  ) {
    return <TemplateChoiceTrainer />;
  } else if (props.program.status === ProgramStatus.EDITION) {
    // le formateur n'a pas encore validé le programme et le programme est encore en édition
    if (validated) {
      return (
        <SubmitedProgram
          setDisplayProgram={props.setDisplayProgram}
          loading={props.loading}
          displayProgram={props.displayProgram}
          setLoading={props.setLoading}
          program={props.program}
          setProgram={props.setProgram}
          setValidated={setValidated}
        />
      );
    } else {
      return (
        <ProgramInEditionTrainer
          program={props.program}
          setProgram={props.setProgram}
          setValidated={setValidated}
        />
      );
    }
  } else if (props.program.status === ProgramStatus.MODERATION || props.program.status === ProgramStatus.CLIENT_VALIDATION || props.program.status === ProgramStatus.VALIDATED) {
    return (
      <SubmitedProgram
        setDisplayProgram={props.setDisplayProgram}
        loading={props.loading}
        displayProgram={props.displayProgram}
        setLoading={props.setLoading}
        program={props.program}
        setProgram={props.setProgram}
        setValidated={setValidated}
      />
    );
  } else {
    return <div>Pas de programme</div>;
  }
}
