import { ElementModel, ElementType } from "../../models/Element.model";
import "./Section.css";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
} from "react";
import { ElementService } from "../../service/program/Element.service";
import { ProgramModel } from "../../models/Program.model";
import { ElementTextField } from "../ElementTextField/ElementTextField";
import { SkillSelected, TypeContext } from "../../context/type.context";
import DeletionAlert from "../DeletionAlert/DeletionAlert";
import { RoleType, UserContext } from "../../context/user.context";
import { useDrop } from "react-dnd";
import { notify, NotificationType } from "../../utils/toaster";
import { SkillsContext } from "../../context/element.context";
import { ModuleModel } from "../../models/Module.model";

export function getName(type: ElementType): string {
  switch (type) {
    case ElementType.EVALUATION:
      return "une évaluation";
    case ElementType.ASSET:
      return "un atout";
    case ElementType.TERMSANDDEADLINEFORACCESS:
      return "une modalités ou délai d'accès :";
    case ElementType.DISABILITYACCESSIBILITY :
      return "une modalité d'accessibilité handicap :";
    case ElementType.MODALITY:
      return "une modalité";
    case ElementType.GOAL:
      return "un objectif";
    case ElementType.PREREQUISITE:
      return "un prérequis";
    case ElementType.SKILL:
      return "une compétence";
    case ElementType.PUBLIC:
        return "un public";
    case ElementType.MODULE:
      return "un module";
    default:
      return "";
  }
}

export function Section(props: {
  content: ElementModel[];
  title: String;
  path?: number[];
  type: ElementType;
  setProgram?: Dispatch<SetStateAction<ProgramModel | null>>;
  parentModule?: string;
  setContent: Dispatch<SetStateAction<ElementModel[]>>;
}) {
  const [, setSkills] = useContext(SkillsContext);
  const [selectedType, setSelectedType] = useContext(TypeContext);

  const [user] = useContext(UserContext);
  const ErrorDisplay = (err: any) => {
    switch (err.status) {
      case 500:
        notify(NotificationType.ERROR, err.data.error || err.statusText);
        break;
      case 400:
        notify(NotificationType.ERROR, err.data.error || err.statusText);
        break;
      default:
        notify(NotificationType.ERROR, err.statusText);
    }
  };



  const handleDelete = (element: ElementModel, index: number) => {
    if (element._id.length > 5) {
      const setContentElements = props.setContent as Dispatch<SetStateAction<(ModuleModel | ElementModel)[]>>;
      new DeletionAlert(element, setContentElements, index);
    }
    let copy = [...props.content];
    copy.splice(index, 1);
    props.setContent(copy);
    
    //Pour la mise a jour de la liste des suggestions des skills
    if(element.type === ElementType.SKILL){
      setSkills((prev) => {
        return prev.filter((skill) => skill.text !== element.text)
      })
    }    
  };

  const add = () => {
    const id = props.content.length;
    let newElement = new ElementModel(
      id.toString(),
      "",
      null,
      props.type,
      false,
      null
    );
    props.setContent([...props.content, newElement]);
  };

  const handleFocus = () => {
    if (props.type === ElementType.SKILL) {
      if (props.parentModule) {
        setSelectedType(
          new SkillSelected(props.parentModule, props.content, props.setContent)
        );
      } else {
        console.log("No parents module");
      }
    } else {
      setSelectedType(props.type);
    }
  };

  const getInfo = (element: ElementModel) => {
    if (element.isRequired) {
      return "Cet élément est obligatoire";
    } else {
      if (element.writer) {
        return (
          "Cet élément a été rédigé par " +
          element.writer.firstname +
          " " +
          element.writer.lastname
        );
      } else {
        return "Cet élément a été rédigé par Matchers";
      }
    }
  };

  const [{ suggestionItem, canDrop, isOver }, drop] = useDrop(() => ({
    accept: props.type,
    collect: (monitor) => ({
      suggestionItem: monitor.getItem() ,
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item: ElementModel) => {
      dropElement(item);
      //Pour la mise a jour de la liste des suggestions des skills
      setSkills((prev) => {
        return [...prev, item]
      })
    },
  }));

  const swapObject = useCallback(
    (item: ElementModel, item2: ElementModel) => {
      let copy = [...props.content];
      const index = copy.indexOf(item);
      const index2 = copy.indexOf(item2);
      copy[index] = item2;
      copy[index2] = item;
      props.setContent(copy);
    },
    [props]
  );
  let dropClass = "";
  if (isOver) {
    //style.border = "#282c34 solid 1px";
    dropClass = "border border-active drop-btn drop-btn-hover";
  } else if (canDrop) {
    //style.border = "#282c34 solid 1px";
    dropClass = "drop-btn drop-btn-waiting";
  }

  const dropElement = (element: ElementModel) => {
    switch (element.type) {
      case ElementType.GOAL:
        ElementService.addGoal(element.text, element._id)
          .then((goal) => {
            props.setContent((previousState) => {
              return [...previousState, goal];
            });
          })
          .catch(ErrorDisplay);
        break;
      case ElementType.MODALITY:
        ElementService.addModality(element.text, element._id)
          .then((modality) => {
            props.setContent((previousState) => {
              return [...previousState, modality];
            });
          })
          .catch(ErrorDisplay);
        break;
      case ElementType.PREREQUISITE:
        ElementService.addPrerequisite(element.text, element._id)
          .then((prerequisite) => {
            props.setContent((previousState) => {
              return [...previousState, prerequisite];
            });
          })
          .catch(ErrorDisplay);
        break;
      case ElementType.PUBLIC:
        ElementService.addPublic(element.text, element._id)
          .then((publicAdded) => {
            props.setContent((previousState) => {
              return [...previousState, publicAdded];
            });
          })
          .catch(ErrorDisplay);
        break;
      case ElementType.ASSET:
        ElementService.addAsset(element.text, element._id)
          .then((asset) => {
            props.setContent((previousState) => {
              return [...previousState, asset];
            });
          })
          .catch(ErrorDisplay);
        break;
      case ElementType.TERMSANDDEADLINEFORACCESS:
        ElementService.addTermsAndDeadlineForAccess(element.text, element._id)
          .then((termsAndDeadlineForAccess) => {
            props.setContent((previousState) => {
              return [...previousState, termsAndDeadlineForAccess];
            });
          })
          .catch(ErrorDisplay);
        break;
      case ElementType.DISABILITYACCESSIBILITY:
        ElementService.addDisabilityAccessibility(element.text, element._id)
          .then((disabilityAccessibility) => {
            props.setContent((previousState) => {
              return [...previousState, disabilityAccessibility];
            });
          })
          .catch(ErrorDisplay);
        break;
      case ElementType.EVALUATION:
        ElementService.addEvaluation(element.text, element._id)
          .then((e) => {
            props.setContent((previousState) => {
              return [...previousState, e];
            });
          })
          .catch(ErrorDisplay);
        break;
      case ElementType.SKILL:
        if (props.parentModule) {
          ElementService.addSkill(props.parentModule, element.text, element._id)
            .then((skill) => {
              props.setContent((previousState) => {
                return [...previousState, skill];
              });
            })
            .catch(ErrorDisplay);
        }
        break;
    }
  };

  return (
    <div className="flex-container" >
      <div
        onClick={handleFocus} 
        className={
          props.type === ElementType.SKILL ? "flex-child alinea2" : "flex-child"
        }
      >
        {props.title ? <h2 id="title">{props.title}</h2> : null}
        <div
          className={
            props.type === selectedType ||
            (selectedType instanceof SkillSelected &&
              selectedType.moduleId === props.parentModule)
              ? "border-active"
              : "border"
          }
        >
          {props.content.length > 0 ? (
            <div>
              {props.type === ElementType.PUBLIC ? (
                <p className="alinea">Cette formation est destinée à des: </p>
              ) : (
                <div></div>
              )}
              {props.content.map((element, index) =>
                // element.isRequired => l'élément est obligatoire, il ne peut être modifié
                // (user === null || user.role === RoleType.CLIENT) => s'il n'y a pas de user ou que l'user est client, il ne peut pas modifier les éléments
                // element._id.length > 5 => l'élément n'est pas en cours de création (il est déjà enregistré en BD)
                // TODO : vérifier et commenter
                element.isRequired ||
                user === null ||
                user.role === RoleType.CLIENT ||
                (user.role !== RoleType.ADMIN &&
                  user.role !== RoleType.MAIN_TRAINER &&
                  element._id.length > 5 &&
                  user.role === RoleType.TRAINER &&
                  user.user !== null &&
                  user.user._id !== element.writer?._id) ? (
                  <Tooltip
                    key={element._id}
                    title={getInfo(element)}
                    followCursor
                  >
                    <li className="required">
                      <label>{element.text}</label>
                    </li>
                  </Tooltip>
                ) : (
                  <div
                    id="elements"
                    className="flex-container element"
                    key={element._id}
                  >
                    <div className="delete">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        id={
                          element._id.length > 5
                            ? element._id
                            : index.toString()
                        }
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          handleDelete(element, index);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </div>
                    <div className="puce">•</div>

                    <ElementTextField
                      key={element._id}
                      placeholder={"Ajouter " + getName(props.type)}
                      size={4}
                      element={element}
                      index={index}
                      content={props.content}
                      setContent={props.setContent}
                      parentModule={props.parentModule}
                      swapObject={swapObject}
                      onEnter={() => {
                        if (index === props.content.length - 1) {
                          add();
                        }
                      }}
                    />
                  </div>
                )
              )}
            </div>
          ) : (
            <p className={"alinea2 no-element " }>Aucun élément</p>
          )}
        </div>
        {(!props.parentModule || props.parentModule.length > 5) && (


          <button ref={drop} className={` alinea2 ${dropClass}`} id="add-element" onClick={add}>

          {suggestionItem == undefined &&
            <span>+ Ajouter <span className="bold">{getName(props?.type)}</span></span>
          }

          { suggestionItem != undefined && suggestionItem.type != undefined && suggestionItem?.getType() == props?.type && suggestionItem?._id != props.parentModule &&
            <span>Déposer {getName(suggestionItem?.getType())}</span>
          }

          </button>
        )}
      </div>
    </div>
  );
}
