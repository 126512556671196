import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Dispatch, SetStateAction, useContext } from "react";
import { ProgramService } from "../../service/program/Program.service";
import { ProgramContext } from "../../context/program.context";
import { notify, NotificationType } from "../../utils/toaster";
import { ActionButton } from "../ActionButton/ActionButton";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationDialog(props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [, setProgram] = useContext(ProgramContext);

  const [step, setStep] = React.useState(1); // Il y a 2 popups de confirmation, step contient le numéro de la popup actuelle
  const [shareProgram, setShareProgram] = React.useState<boolean>(false);

  const submitTile = "Souhaitez-vous soumettre votre programme ?";
  const submitDescription = "Vous êtes sur le point de soumettre le programme de formation, vous ne pourrez plus faire de modification après cette validation.";
  const submitLeftButtonText = "Annuler";
  const submitRightButtonText = "Valider";
  
  const submitFirstLeftButtonFunction = () => {
    props.setOpen(false);
  }
  const submitFirstRightButtonFunction = () => {
    // Passer à la deuxième popup
    submitProgram(shareProgram);
  }

  const submitProgram = (shouldBeShared: boolean) => {
    ProgramService.submitProgramForModeration(shouldBeShared)
      .then((program) => {
        setProgram(program);
        props.setOpen(false);
        window.location.reload(); // <- ugly
      })
      .catch((e) => {
        props.setOpen(false);
        notify(NotificationType.ERROR, "Erreur : " + e.data.error);
      });
  }

  const shareProgramTitle = "Acceptez-vous d’ajouter votre programme à la bibliothèque Matchers ?"
  const shareProgramDescription = "Si vous acceptez, votre programme sera partagé avec la communauté matchers, il pourra être réutilisé et proposé à de futurs experts ayant une thématique proche de la vôtre.";
  const shareProgramLeftButtonText = "Je refuse";
  const shareProgramRightButtonText = "J'accepte";

  const ShareProgramSecondLeftButtonFunction = () => {    
    setShareProgram(false)
    setStep(2);
  }
  const shareProgramSecondRightButtonFunction = () => {    
    setShareProgram(true)
    setStep(2);
  }

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setStep(1);
          props.setOpen(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* The title should have the main color of mui theme */}
        <DialogTitle sx={{color: 'primary.main'}}>
          {step === 1 ?  shareProgramTitle : submitTile}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {step === 1 ? shareProgramDescription : submitDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <ActionButton
            onClick={() => {
              step === 1 ? ShareProgramSecondLeftButtonFunction() :  submitFirstLeftButtonFunction() 
            }}
            text={step === 1 ?  shareProgramLeftButtonText : submitLeftButtonText }
            label={step === 1 ? shareProgramLeftButtonText : submitLeftButtonText}
            emptyColor={true}
            style={{ marginRight: "auto" }}
          />
          <ActionButton
            onClick={() => {
              step === 1 ? shareProgramSecondRightButtonFunction() : submitFirstRightButtonFunction()
            }}
            text={step === 1 ? shareProgramRightButtonText : submitRightButtonText}
            label={step === 1 ? shareProgramRightButtonText : submitRightButtonText }
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
