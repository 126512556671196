import { ProgramModel } from "../../models/Program.model";
import { ElementModel } from "../../models/Element.model";
import axios from "axios";
import { plainToInstance } from "class-transformer";

export class ElementService {
  /* *** ELEMENT *** */
  public static addElement(
    text: string,
    model: string | null,
    type: string
  ): Promise<ElementModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/" + type, {
          text: text,
          model: model,
        })
        .then((response) => {
          let elementJson = response.data;
          let element = plainToInstance(ElementModel, elementJson);
          // @ts-ignore
          resolve(element);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /* *** GOAL *** */
  public static addGoal(
    text: string,
    model: string | null = null
  ): Promise<ElementModel> {
    return this.addElement(text, model, "addGoal");
  }

  public static updateGoal(id: string, text: string): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/updateGoal", {
          id: id,
          text: text,
        })
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static deleteGoal(id: string): Promise<ElementModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.REACT_APP_API_URL + "/training-program/deleteGoal",
          { data: { id: id } }
        )
        .then((response) => {
          let goalsJson = response.data.goals;
          let goals = plainToInstance(ElementModel, goalsJson);

          resolve(goals);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /* *** MODALITY *** */
  public static addModality(
    text: string,
    model: string | null = null
  ): Promise<ElementModel> {
    return this.addElement(text, model, "addModality");
  }

  public static updateModality(
    id: string,
    text: string
  ): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/updateModality",
          { id: id, text: text }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static deleteModality(id: string): Promise<ElementModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.REACT_APP_API_URL + "/training-program/deleteModality",
          { data: { id: id } }
        )
        .then((response) => {
          let modalitiesJson = response.data.modalities;
          let modalities = plainToInstance(ElementModel, modalitiesJson);
          resolve(modalities);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /* *** PREREQUISITE *** */
  public static addPrerequisite(
    text: string,
    model: string | null = null
  ): Promise<ElementModel> {
    return this.addElement(text, model, "addPrerequisite");
  }
  public static updatePrerequisite(
    id: string,
    text: string
  ): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL +
            "/training-program/updatePrerequisite",
          { id: id, text: text }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static deletePrerequisite(id: string): Promise<ElementModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.REACT_APP_API_URL +
            "/training-program/deletePrerequisite",
          { data: { id: id } }
        )
        .then((response) => {
          let prerequisitesJson = response.data.prerequisites;
          let prerequisites = plainToInstance(ElementModel, prerequisitesJson);
          resolve(prerequisites);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /* *** PUBLIC *** */
  public static addPublic(
    text: string,
    model: string | null = null
  ): Promise<ElementModel> {
    return this.addElement(text, model, "addPublic");
  }
  public static updatePublic(id: string, text: string): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/updatePublic",
          { id: id, text: text }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static deletePublic(id: string): Promise<ElementModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.REACT_APP_API_URL + "/training-program/deletePublic",
          { data: { id: id } }
        )
        .then((response) => {
          let publicsJson = response.data.publics;
          let publics = plainToInstance(ElementModel, publicsJson);
          resolve(publics);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /* *** EVALUATION *** */
  public static addEvaluation(
    text: string,
    model: string | null = null
  ): Promise<ElementModel> {
    return this.addElement(text, model, "addEvaluation");
  }

  public static updateEvaluation(
    id: string,
    text: string
  ): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/updateEvaluation",
          { id: id, text: text }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static deleteEvaluation(id: string): Promise<ElementModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.REACT_APP_API_URL + "/training-program/deleteEvaluation",
          { data: { id: id } }
        )
        .then((response) => {
          let evaluationJson = response.data.evaluation;
          let evaluation = plainToInstance(ElementModel, evaluationJson);
          resolve(evaluation);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /* *** ASSET *** */
  public static addAsset(
    text: string,
    model: string | null = null
  ): Promise<ElementModel> {
    return this.addElement(text, model, "addAsset");
  }

  public static updateAsset(id: string, text: string): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/updateAsset",
          { id: id, text: text }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static deleteAsset(id: string): Promise<ElementModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.REACT_APP_API_URL + "/training-program/deleteAsset",
          { data: { id: id } }
        )
        .then(
          (response) => {
            let assetsJson = response.data.assets;
            let assets = plainToInstance(ElementModel, assetsJson);
            resolve(assets);
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  /* *** MODALITES ET DELAI D'ACCÈS *** */
  public static addTermsAndDeadlineForAccess(
    text: string,
    model: string | null = null
  ): Promise<ElementModel> {
    return this.addElement(text, model, "addTermsAndDeadlineForAccess");
  }

  public static updateTermsAndDeadlineForAccess(id: string, text: string): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/updateTermsAndDeadlineForAccess",
          { id: id, text: text }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static deleteTermsAndDeadlineForAccess(id: string): Promise<ElementModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.REACT_APP_API_URL + "/training-program/deleteTermsAndDeadlineForAccess",
          { data: { id: id } }
        )
        .then(
          (response) => {
            let termsAndDeadlineForAccessJson = response.data.termsAndDeadlineForAccess;
            let termsAndDeadlineForAccess = plainToInstance(ElementModel, termsAndDeadlineForAccessJson);
            resolve(termsAndDeadlineForAccess);
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  /* *** ACCESSIBILITÉ HANDICAP  *** */
  public static addDisabilityAccessibility(
    text: string,
    model: string | null = null
  ): Promise<ElementModel> {
    return this.addElement(text, model, "addDisabilityAccessibility");
  }

  public static updateDisabilityAccessibility(id: string, text: string): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/updateDisabilityAccessibility",
          { id: id, text: text }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static deleteDisabilityAccessibility(id: string): Promise<ElementModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.REACT_APP_API_URL + "/training-program/deleteDisabilityAccessibility",
          { data: { id: id } }
        )
        .then(
          (response) => {
            let disabilityAccessibilityJson = response.data.disabilityAccessibility;
            let disabilityAccessibility = plainToInstance(ElementModel, disabilityAccessibilityJson);
            resolve(disabilityAccessibility);
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  }

  /* *** SKILL *** */
  public static addSkill(
    moduleId: string,
    text: string,
    model: string | null = null
  ): Promise<ElementModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/module/addSkill",
          { moduleId: moduleId, text: text, model: model }
        )
        .then((response) => {
          let skillJson = response.data;
          let skill = plainToInstance(ElementModel, skillJson);
          // @ts-ignore
          resolve(skill);
        })
        .catch((error) => {
          console.log(error.request);
          reject(error);
        });
    });
  }
  public static updateSkill(id: string, text: string): Promise<ElementModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL +
            "/training-program/module/updateSkill",
          { id: id, text: text }
        )
        .then((response) => {
          let program = response.data.program;
          let skillJson = response.data.updatedSkill;
          
          let skill = plainToInstance(ElementModel, skillJson);

          // @ts-ignore
          resolve(skill);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static deleteSkill(id: string): Promise<ElementModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL +
            "/training-program/module/deleteSkill",
          { id: id }
        )
        .then((response) => {
          let skillsJson = response.data;
          let skills = plainToInstance(ElementModel, skillsJson);
          resolve(skills);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
}
