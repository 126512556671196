import Headers from "../../../Component/Headers/Headers";
import { ProgramModel, ProgramStatus } from "../../../models/Program.model";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import { Status } from "../../../Component/Status/Status";
import { PdfDocument } from "../../../Component/PdfDocument/PdfDocument";
import { ActionButton } from "../../../Component/ActionButton/ActionButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { ProgramService } from "../../../service/program/Program.service";
import { PdfService } from "../../../service/pdf/Pdf.service";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import TrainersIcon from "@mui/icons-material/ConnectWithoutContact";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import { RoleType, UserContext } from "../../../context/user.context";
import InfoToUser from "../../../Component/InfoToUser/InfoToUser";
import ProgramInEdition from "../../mainTrainerPage/ProgramInEdition/ProgramInEdition";
import { NotificationType, notify } from "../../../utils/toaster";
import DialogComment from "../../../Component/DialogComment/DialogComment";
import {Comment} from "../../../models/Comment.model"

export default function SubmitedProgram(props: {
  program: ProgramModel;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  displayProgram: boolean | null;
  setDisplayProgram: Dispatch<SetStateAction<boolean | null>>;
  setProgram: Dispatch<SetStateAction<ProgramModel | null>>;
  setValidated: Dispatch<SetStateAction<boolean>>;
}) {
  const createPDF = () => {
    ProgramService.getProgram().then((returnProgram: ProgramModel) => {
      PdfService.generatePdf(returnProgram).save(returnProgram.title + ".pdf");
    });
  };
  const [user] = useContext(UserContext);
  const [edit, setEdit] = useState(false);

  const [showCommentDialog, setCommentDialog] = useState(false);

  const onProgramInvalidated = (comment:string|undefined) => {

    setCommentDialog(false)
    
    return ProgramService.moderationInvalidate(comment)
    .then((program) => {
      props.setProgram(program);
      props.setValidated(false);
      notify(NotificationType.INFO, "Programme invalidé, les formateurs vont pouvoir retourner à l'édition du programme", {autoClose: 7000});
    })
    .catch((error) => {
      notify(NotificationType.ERROR, "Erreur lors de l'invalidation du programme");
    });
  }


  const renderModerationActionsButtons = () => {
    return (
      <>
        <Tooltip
          placement="bottom"
          title={"Renvoyer au(x) formateur(s) car le programme nécessite modification de leur(s) part(s)"}
        >
          <div>
            <ActionButton
              label={"unvalidate"}
              text={"Invalider"}
              icon={ TrainersIcon }
              onClick={() => { setCommentDialog(true) }}
              disable={false}
            />
          </div>
        </Tooltip>

        <Tooltip
          placement="bottom"
          title={"Demander au client de valider le programme"}
        >
          <div>
            <ActionButton
              label={"validate"}
              text={"Validation client"}
              icon={CheckIcon}
              onClick={() => {
                ProgramService.moderationValidate()
                  .then((program) => {
                    props.setProgram(program);
                    notify(NotificationType.SUCCESS, "Programme validé");
                  })
                  .catch((error) => {
                    notify(NotificationType.ERROR, "Erreur lors de la validation du programme");
                  });
                props.setValidated(true);
              }}
              disable={false}
            />
          </div>
        </Tooltip>

        {/* shortcut to bypass client validation */}
        <Tooltip
          placement="bottom"
          title={"Finaliser le programme - un mail sera envoyé au client, formateur(s) et administrateur - chacun pourra télécharger le PDF du programme"}
        >
          <div>
            <ActionButton
              label={"unvalidate"}
              text={"Finaliser sans validation client"}
              icon={PdfIcon}
              onClick={() => { finalizeProgram() }}
              disable={false}
            />
          </div>
        </Tooltip>
      </>
    )
  }

  const finalizeProgram = () => {
    ProgramService.finalization()
    .then((program) => {
      props.setProgram(program);
      notify(NotificationType.SUCCESS, "Programme finalisé");
    })
    .catch((error) => {
      notify(NotificationType.ERROR, "Erreur lors de la finalisation du programme");
    });
    props.setValidated(true);
  }

  /* finalize the process */
  const renderAdminValidationActionsButtons = () => {
    return (
      <>      
        <ActionButton
          label={"validate"}
          text={"Finaliser"}
          icon={CheckIcon}
          onClick={() => { finalizeProgram() }}
          disable={false}
        />

        <ActionButton
          label={"validate"}
          text={"Demander des modification au(x) formateur(s)"}
          icon={ TrainersIcon }
          onClick={() => { setCommentDialog(true) }}
          disable={false}
        />

      </>
    )
  }


  return (
    <div>
      {!edit ? (
        <div>
          <div style={{ height: "100px" }}>
            <Headers showSave={null} status={props.program.status} />
          </div>
          <h1
            style={{
              padding: "0",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            {
              props.program.status === ProgramStatus.NOT_STARTED
                ? "Programme inaccessible"
                : "Visualisation du programme"
            }
          </h1>
          {
            props.program.status === ProgramStatus.MODERATION
            ? <InfoToUser
                text={ user?.role === RoleType.ADMIN
                  ? "En tant qu'admin, vous devez valider ou invalider le programme avant de passer à la phase de validation finale pour le client."
                  : "Le programme est en cours de validation, vous serez notifié lorsque vous pourrez accéder au document."
                }
                type={"warning"}
              />
            : props.program.status === ProgramStatus.CLIENT_VALIDATION ?
              <InfoToUser
                text={"Le programme est en cours de validation par le client, vous serez notifié lorsque cela sera fait."}
                type={"warning"}
              />
            : props.program.status === ProgramStatus.VALIDATED ?
            <InfoToUser
              text={"Le programme est finalisé, vous pouvez le consulter ou télécharger sa version PDF."}
              type={"success"}
            />
            : props.program.status === ProgramStatus.ADMIN_VALIDATION ?
            <InfoToUser
              text={"Le programme a été validé par le client, vous devez apporter la validation finale"}
              type={"success"}
            />
            : <></>
          }
          {/* ---- STATUS OF THE PROGRAM ---- */}
          <Box sx={{ marginLeft: "100px", marginRight: "100px" }}>
            <Grid container alignItems="center">
              {user && user.role === RoleType.ADMIN && (
                <>
                  <ActionButton
                    label={"modify"}
                    text={"Modifier"}
                    icon={EditIcon}
                    onClick={() => setEdit(true)}
                    disable={false}
                  />
                  { props.program.status === ProgramStatus.MODERATION && 
                    renderModerationActionsButtons()
                  }
                  { props.program.status === ProgramStatus.ADMIN_VALIDATION && 
                    renderAdminValidationActionsButtons()
                  }
                </>
              )}

              
              {/* Download PDF button - admin can always download it in any states*/}
              <Box sx={{ marginLeft: "100px" }}>                
              { ((user && user.role === RoleType.ADMIN) ||  props.program.status == ProgramStatus.VALIDATED) &&
                <ActionButton
                label={"download"}
                text={"Télécharger (PDF)"}
                icon={FileDownloadIcon}
                onClick={() =>   ProgramService.openPdfInTab(props.program._id, props.program.title) }
                disable={false}
              />
                }

              </Box>
              <Grid item xs />
              {/* ---- STATUS AND NB HOURS OF THE PROGRAM ---- */}
              <Stack direction="row" alignItems="center" spacing={2}>
                <Status status={props.program.status} />
              </Stack>
            </Grid>
          </Box>
          {/* Display PDF document */}
          <Box
            key={"PdfDocument"}
            className="program"
            sx={{
              bgcolor: "info.light",
              borderRadius: "16px",
              marginLeft: "100px",
              marginRight: "100px",
              padding: "20px",
            }}
          >
            <PdfDocument hidden={false} />
          </Box>
        </div>
      ) : (
        <ProgramInEdition
          program={props.program}
          setProgram={props.setProgram}
          loading={props.loading}
          setLoading={props.setLoading}
          displayProgram={props.displayProgram}
          setDisplayProgram={props.setDisplayProgram}
        />
      )}

        <DialogComment open={showCommentDialog} onClose={() => setCommentDialog(false)} onInvalidate={onProgramInvalidated}></DialogComment>

    </div>
  );
}
