import "./EmptyModule.css";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { ElementModel, ElementType } from "../../models/Element.model";
import { ModuleModel } from "../../models/Module.model";
import { DebugContext } from "../../context/debugContext";
import { useDrop } from "react-dnd";

export function EmptyModule(props: {
  module:ModuleModel;
  setModules: Dispatch<SetStateAction<ModuleModel[]>>;
  setSkills: Dispatch<SetStateAction<ElementModel[]>>;
  addModuleFromSuggestion: (module: ModuleModel) => void;
  addCompetenceFromSuggestion: (competence: ElementModel) => void;
  parentModule?: string;
}) {

  const [debug,] = useContext(DebugContext);

  const handleAddElement = (event: React.MouseEvent<HTMLButtonElement>) => {
    let newElement = new ElementModel(
      "0",
      "",
      null,
      ElementType.SKILL,
      false,
      null
    );
    props.setSkills([newElement]);
  };

  const handleAddModule = (event: React.MouseEvent<HTMLButtonElement>) => {
    let newModule = new ModuleModel("0", "", [], [], null, null, 1);
    props.setModules([newModule]);
  };




  // when dropping module from suggestions
  const [{  suggestionModuleItem, isOverSuggestion, canDropSuggestion, suggestionIsOverCurrent }, dropSuggestionModule] = useDrop(() => ({
    accept: ElementType.MODULE,
    drop: (item: ModuleModel) => {
      props.addModuleFromSuggestion(item as ModuleModel)
    },
    collect: (monitor) => ({
      suggestionModuleItem: monitor.getItem(),
      canDropSuggestion: monitor.canDrop(),
      isOverSuggestion: monitor.isOver(),
      suggestionIsOverCurrent: monitor.isOver({ shallow: true }),
    }),
  }));

  // when dropping competence from suggestions
  const [{ competenceItem, isOverCompetence, canDropCompetence, competenceIsOverCurrent }, dropCompetence] = useDrop(() => ({
    accept: ElementType.SKILL,
    drop: (item: ElementModel) => {
      props.addCompetenceFromSuggestion(item)
    },
    collect: (monitor) => ({
      competenceItem: monitor.getItem(),
      canDropCompetence: monitor.canDrop(),
      isOverCompetence: monitor.isOver(),
      competenceIsOverCurrent: monitor.isOver({ shallow: true }),
    }),
  }));

  
  
  let draggingSecondLevelModule =  suggestionModuleItem != undefined && suggestionModuleItem._id != undefined &&
                                suggestionModuleItem?.getType() == ElementType.MODULE  &&
                                suggestionModuleItem?.modules.length > 0

  let dropModuleClass = "";
  if (suggestionIsOverCurrent && !draggingSecondLevelModule) {
    dropModuleClass = "drop-btn drop-btn-hover ";
  } else if (canDropSuggestion && !draggingSecondLevelModule) {
    dropModuleClass = "drop-btn  drop-btn-waiting";
  }

  

  let dropCompetenceClass = "";
  if (competenceIsOverCurrent) {
    dropCompetenceClass = "drop-btn drop-btn-hover ";
  } else if (canDropCompetence) {
    dropCompetenceClass = "drop-btn  drop-btn-waiting";
  }


  function preview(arg0: React.ReactElement<any, string | React.JSXElementConstructor<any>> | null) {
    throw new Error("Function not implemented.");
  }

  return (
    <React.Fragment>
      <span className="alinea3 inline">

    
        <button  style={{ display: draggingSecondLevelModule == true ? "none" : "inline-block" } } ref={dropSuggestionModule} className={`alinea ${dropModuleClass}`} id="add-module" onClick={handleAddModule}>
          + Ajouter un sous module à <strong>{props.module.title}</strong>
        </button>
        

        <button style={{ display: draggingSecondLevelModule == true ? "none" : "inline-block" } }  ref={dropCompetence} className={`alinea ${dropCompetenceClass}`} id="add-element" onClick={handleAddElement}>
          + Ajouter une compétence à <strong>{props.module.title}</strong>
        </button>


      </span>
    </React.Fragment>
  );
}
