import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  Stack,
} from "@mui/material";
import "./SuggestionCard.css";
import { ModuleSuggestionInterface } from "../../models/IModuleSuggestion.model";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ProgramContext } from "../../context/program.context";
import {
  AssetsContext,
  TermsAndDeadlineForAccessContext,
  DisabilityAccessibilityContext,
  EvaluationContext,
  GoalsContext,
  ModalitiesContext,
  PrerequisitesContext,
  PublicsContext,
} from "../../context/element.context";
import { ElementModel, ElementType } from "../../models/Element.model";
import { ElementService } from "../../service/program/Element.service";
import { ModuleService } from "../../service/program/Module.service";
import { ModuleModel } from "../../models/Module.model";
import {
  ModuleSelected,
  SkillSelected,
  TypeContext,
} from "../../context/type.context";
import { useDrag, useDrop } from "react-dnd";
import { themeMatchersLight } from "../../theme";
import { notify, NotificationType } from "../../utils/toaster";
import { GlobalDragContext } from "../../context/globalDrag.context";
import { DebugContext } from "../../context/debugContext";

export function SuggestionCard(props: {
  
  element: ModuleSuggestionInterface;
  suggestionList: ModuleSuggestionInterface[];
  setSuggestionList: React.Dispatch<
    React.SetStateAction<ModuleSuggestionInterface[]>
  >;
  index: number;
}) {

  const [debug, ] = useContext(DebugContext);
  const [selectedType] = useContext(TypeContext);
  const [program] = useContext(ProgramContext);
  const [goals, setGoals] = useContext(GoalsContext);
  const [modalities, setModalities] = useContext(ModalitiesContext);
  const [publics, setPublics] = useContext(PublicsContext);
  const [prerequisites, setPrerequisites] = useContext(PrerequisitesContext);
  const [evaluation, setEvaluation] = useContext(EvaluationContext);
  const [assets, setAssets] = useContext(AssetsContext);
  const [, setTermsAndDeadlineForAccess] = useContext(TermsAndDeadlineForAccessContext);
  const [, setDisabilityAccessibility] = useContext(DisabilityAccessibilityContext);

  const [globalDrag, setGlobalDrag ] = useContext(GlobalDragContext);

  const ErrorDisplay = (err: any) => {
    switch (err.status) {
      case 500:
        notify(NotificationType.ERROR, err.data.error || err.statusText);
        break;
      case 400:
        notify(NotificationType.ERROR, err.data.error || err.statusText);
        break;
      default:
        notify(NotificationType.ERROR, err.statusText);
    }
  };

  const removeElement = (
    error: unknown,
    idToRemove: string,
    setFunction : React.Dispatch<React.SetStateAction<ElementModel[]>> | React.Dispatch<React.SetStateAction<ModuleModel[]>>,
    array: Array<any>
  ) : void => {
    ErrorDisplay(error);
    setFunction(array.filter(element => element._id !== idToRemove))
  }

  const addElement = (element: ModuleSuggestionInterface) => {
    if (element instanceof ModuleModel) {
      if (selectedType instanceof ModuleSelected) {
        const tempModule = new ModuleModel(element._id, element.title, element.modules, element.skills, element.writer, element.duration, element.number, element.model)
        selectedType.setModules((previousValue) => {
          return [...previousValue, tempModule];
        });
        try {
          if (selectedType.subModule) {
            if (selectedType.id) {
              ModuleService.addModuleInModuleFromSuggestion(
                element._id,
                selectedType.id,
                element.origin
              )
            } else {
              console.log("Missing id");
              throw new Error("Missing id");
            }
          } else {
            ModuleService.addModuleInTrainingProgramFromSuggestion(element._id, element.origin)
          }
        } catch (error) {
          removeElement(error, tempModule._id, selectedType.setModules, selectedType.modules)
        }
      } else {
        console.log("error selected type");
      }
    } else if (element instanceof ElementModel) {
      switch (element.type) {
        case ElementType.GOAL:
          const tempGoal = new ElementModel(element._id, element.text, element.writer, ElementType.GOAL, false);
          setGoals((previousValue) => {
            return [...previousValue, tempGoal];
            });
          ElementService.addGoal(element.text, element._id)
            .catch(e => {
              removeElement(e, tempGoal._id, setGoals, goals)
            });
          break;
        case ElementType.MODALITY:
          const tempModality = new ElementModel(element._id, element.text, element.writer, ElementType.MODALITY, false);
          ElementService.addModality(element.text, element._id)
            .catch(e => {
              removeElement(e, tempModality._id, setModalities, modalities)
            });
          break;
        case ElementType.PREREQUISITE:
          const tempPrerequisite = new ElementModel(element._id, element.text, element.writer, ElementType.PREREQUISITE, false);
          setPrerequisites((previousValue) => {
            return [...previousValue, tempPrerequisite];
            });
          ElementService.addPrerequisite(element.text, element._id)
            .catch(e => {
                removeElement(e, tempPrerequisite._id, setPrerequisites, prerequisites)
            });
          break;
        case ElementType.PUBLIC:
          const tempPublic = new ElementModel(element._id, element.text, element.writer, ElementType.PUBLIC, false);
          setPublics((previousValue) => {
            return [...previousValue, tempPublic];
            });
          ElementService.addPublic(element.text, element._id)
            .catch(e => {
                removeElement(e, tempPublic._id, setPublics, publics)
            });
          break;
        case ElementType.ASSET:
          const tempAsset = new ElementModel(element._id, element.text, element.writer, ElementType.ASSET, false);
          ElementService.addAsset(element.text, element._id)
            .catch(e => {
                removeElement(e, tempAsset._id, setAssets, assets)
            });
          break;
        case ElementType.TERMSANDDEADLINEFORACCESS:
          ElementService.addTermsAndDeadlineForAccess(element.text, element._id)
            .then((termsAndDeadlineForAccess) => {
              setTermsAndDeadlineForAccess((previousValue) => {
                return [...previousValue, termsAndDeadlineForAccess];
                });
            })
            .catch(ErrorDisplay);
          break;
          case ElementType.DISABILITYACCESSIBILITY:
            ElementService.addDisabilityAccessibility(element.text, element._id)
              .then((disabilityAccessibility) => {
                setDisabilityAccessibility((previousValue) => {
                  return [...previousValue, disabilityAccessibility];
                  });
              })
              .catch(ErrorDisplay);
            break;
        case ElementType.EVALUATION:
          const tempEvaluation = new ElementModel(element._id, element.text, element.writer, ElementType.EVALUATION, false);
          ElementService.addEvaluation(element.text, element._id)
            .catch(e => {
                removeElement(e, tempEvaluation._id, setEvaluation, evaluation)
            });
          break;
        case ElementType.SKILL:
          if (selectedType instanceof SkillSelected) {
            const tempSkill = new ElementModel(element._id, element.text, element.writer, ElementType.SKILL, false);
            selectedType.setSkills((previousValue) => {
              return [...previousValue, tempSkill];
              });
            ElementService.addSkill(
              selectedType.moduleId,
              element.text,
              element._id
            ).catch(e => {
                removeElement(e, tempSkill._id, selectedType.setSkills, selectedType.skills)
            });
          }
          break;
      }
    } else {
      console.log("error");
    }
  };


  

 
  const [{isDragging} , drag, preview] = useDrag(
    () => ({
      type: props.element.getType(),
      item: props.element,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
       
      }),
    }),
    [props.element]
  );



  useEffect(() => {

    if(debug.config?.debug){
      console.log("suggestion card is dragging", isDragging)
    }
    
    globalDrag.suggestionCardIsDragging = isDragging
    setGlobalDrag({...globalDrag})     

  }, [isDragging])
  

  const handleClick = () => {
    const liste = props.suggestionList.filter((element) => {
      return element !== props.element;
    });
    props.setSuggestionList(liste);
    // BD
    if (program) {
      addElement(props.element);
    }
  };

  const [showMore, setShowMore] = useState(false);
  const [height, setHeight] = useState(0);
  const [maxheight, setMaxHeight] = useState("300px");
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      setHeight(ref.current!.clientHeight);
    }
  }, [ref, height]);

  if (props.element instanceof ElementModel) {
    return (
      <div className="card">
        <Card ref={drag}>
          <CardActionArea  className="cursor-grab" ref={preview} /* onClick={handleClick} */ >
            <CardHeader
              avatar={<div className="type">{props.element.getType()}</div>}
              sx={{
                "& .MuiCardHeader-avatar": {
                  color: "warning.main",
                },
              }}
            />
            <Box sx={{ padding: "16px", paddingTop: "0" }}>
              {"• " + props.element.toString()}
            </Box>
          </CardActionArea>
        </Card>
      </div>
    );
  } else if (props.element instanceof ModuleModel) {
    return (
      <div className="card">
        <Card ref={drag}>
          <CardActionArea className="cursor-grab" ref={preview} /* onClick={handleClick} */ >
            <CardHeader
              avatar={
                <div className="type">{props.element.getModuleDeep()}</div>
              }
              sx={{
                "& .MuiCardHeader-avatar": {
                  color: "warning.main",
                },
              }}
            />
            <Box
              sx={{
                padding: "16px",
                paddingTop: "0",
                maxHeight: maxheight,
                overflow: "hidden",
              }}
            >
              <div ref={ref}>
                <h3>{props.element.toString()}</h3>
                {props.element.modules.map((module, index) => {
                  return (
                    <div key={module._id}>
                      <h4>{index + 1 + "- " + module}</h4>
                      {module.skills.map((skill) => (
                        <li key={skill._id}>
                          <label key={skill._id}>{"• " + skill}</label>
                        </li>
                      ))}
                    </div>
                  );
                })}
                {props.element.skills.map((skill) => (
                  <li key={skill._id}>
                    <label key={skill._id}>{"• " + skill}</label>
                  </li>
                ))}
              </div>
            </Box>
          </CardActionArea>
          {height >= 300 && !showMore ? (
            <Stack justifyContent="flex-end" direction="row">
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: themeMatchersLight.palette.info.dark,
                }}
                onClick={() => {
                  setMaxHeight("none");
                  setShowMore(true);
                }}
              >
                ...Voir plus
              </Button>
            </Stack>
          ) : null}
          {showMore ? (
            <Stack
              justifyContent="flex-end"
              direction="row"
              sx={{ backgroundColor: "white", overflow: "hidden" }}
            >
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: themeMatchersLight.palette.info.dark,
                }}
                onClick={() => {
                  setMaxHeight("300px");
                  setShowMore(false);
                }}
              >
                ...Voir moins
              </Button>
            </Stack>
          ) : null}
        </Card>
      </div>
    );
  } else {
    return <div>error</div>;
  }
}
