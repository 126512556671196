import React from "react";
import { isProduction } from "../utils/runtime";

export interface DebugContextData{
  displayDebugTool:boolean;
  debug:boolean;
  debugDom:boolean;    
}

export class Debug{
    constructor(public config:DebugContextData|undefined){        
        
    }
}

export const DebugContext = React.createContext<
  [Debug, React.Dispatch<React.SetStateAction<Debug>>]
>([ new Debug(undefined) , () => {}]);
