


export class TopicModel {
    public _id: string;
    public label: string;

    constructor(_id: string, label: string) {
        this._id = _id;
        this.label = label;
    }
}