import "./HoursTextField.css";
import {  useState, useContext, useRef } from "react";
import { RoleType, UserContext } from "../../context/user.context";
import { Box, Tooltip, Typography } from "@mui/material";
import { ProgramService } from "../../service/program/Program.service";
import { notify, NotificationType } from "../../utils/toaster";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export function HoursTextField(props: {
  hours: { nbHours: number; fixed: boolean; minNbHours: number; maxNbHours: number };
}) {
  const [nbHours, setNbHours] = useState(props.hours.nbHours);

  const debounceTimeout = useRef<any>();

  const incrementHours = () => {
    if (nbHours < props.hours.maxNbHours) {
      setNbHours(nbHours + 1);
      debounceUpdateNbHours(nbHours, nbHours + 1);
    }
  };
  
  const decrementHours = () => {
    if (nbHours > props.hours.minNbHours) {
      setNbHours(nbHours - 1);
      debounceUpdateNbHours(nbHours, nbHours - 1);
    }
  };
  
  const debounceUpdateNbHours = (oldValue: number, newValue: number) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      updateNbHours(oldValue, newValue);
    }, 2000);
  };

  const updateNbHours = (oldValue: number, newValue: number) => {
    ProgramService.updateHoursProgram(
      newValue,
      props.hours?.fixed
    ).then((response) => {
      notify(NotificationType.SUCCESS, "Le nombre d'heures a été modifié", { position: 'top-center', closeOnClick: true, pauseOnHover: false, draggable: false});
    }).catch((error) => {
      notify(NotificationType.ERROR, "Le nombre d'heures n'a pas pu être modifié : " + error);
      setNbHours(oldValue);
    });
  };

  const [user] = useContext(UserContext);
  const [isHovered, setIsHovered] = useState(false);

  const shouldOpenTooltip = () => {
    if (user?.role !== RoleType.ADMIN && user?.role !== RoleType.MAIN_TRAINER) {
      return isHovered;
    }

    if (props.hours.maxNbHours === props.hours.minNbHours) {
      return isHovered;
    }

    return false;
  };
  
  const buttonStyle = {
    pink: {
      backgroundColor: "#D93174",
      color: "white",
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: "#dd5c90",
        color: "white",
      }
    },
    grey: {
      backgroundColor: "#d1d1d1",
      color: "white",
      borderRadius: "50%",
      cursor: "default",
      "&:hover": {
        backgroundColor: "#d1d1d1",
        color: "white",
      }
    },
  };

  return (
    // <Box sx={{ display: "inline", float: "right", width: 400, mr: '20px'}}>
    <Box
      sx={{ float: "right", width: 290 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Tooltip
        title={"Élement déjà défini et non modifiable"}
        open={shouldOpenTooltip()}
      >
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #aeaeae",
          borderRadius: "5px",
          padding: "0 5px 0 5px",
        }}>
          <IconButton
            size="small"
            onClick={decrementHours}
            sx={
              nbHours===props.hours.minNbHours ? buttonStyle.grey : buttonStyle.pink
            }
            disabled={!props.hours.fixed && (user?.role!==RoleType.ADMIN && user?.role!==RoleType.MAIN_TRAINER)}
          >
            <RemoveIcon />
          </IconButton>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid #D93174",
            borderRight: "1px solid #D93174",
            padding: "0 5px 0 5px",
            }}>
            <Typography
              sx={{
                width: "100%",
                margin: "0 5px 0 5px",
                "& .MuiFilledInput-root": {
                  backgroundColor: "white",
                },
                color: "#646464",
                fontSize: "1rem",
              }}
            >
              Durée de la formation :
            </Typography>
            <Typography
              sx={{
                width: "100%",
                margin: "0 5px 0 5px",
                "& .MuiFilledInput-root": {
                  backgroundColor: "white",
                },
                color: "#000000",
                fontSize: "1rem",
              }}
            >
              {nbHours + "h"}
            </Typography>
          </Box>
          <IconButton
            size="small"
            onClick={incrementHours}
            sx={
              nbHours===props.hours.maxNbHours ? buttonStyle.grey : buttonStyle.pink
            }
            disabled={!props.hours.fixed && (user?.role!==RoleType.ADMIN && user?.role!==RoleType.MAIN_TRAINER)}
          >
            <AddIcon />
          </IconButton>
        </div>
      </Tooltip>
    </Box>
  );
}
