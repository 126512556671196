/**
 * @file params.ts
 * @description Ce fichier est destiné à contenir toutes les configurations possibles de l'application.
 */
export default class Params {

    // Fuzzysort search
    // Attention, ces valeurs peuvent être seulement négatives. Plus la valeur est proche de 0 et plus la recherche est précise
    static precision_fuzzy_suggestion: number = -10000;
    static precision_fuzzy_search: number = -10000;

}