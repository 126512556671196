import { Typography } from "@mui/material";
import "./Note.css";

export function Note() {
  return (
    <div className="flex-container">
      <div className="flex-child">
        <div>
          <h2>Note</h2>
          <Typography id="article-name">
            Article L. 6353-1 DU CODE DU TRAVAIL{" "}
          </Typography>
          <Typography id="article-content">
            « Les actions de formation professionnelle mentionnées à l'article
            L. 6313-1 sont réalisées conformément à un programme préétabli qui,
            en fonction d'objectifs déterminés, précise le niveau de
            connaissances préalables requis pour suivre la formation, les moyens
            pédagogiques, techniques et d'encadrement mis en œuvre ainsi que les
            moyens permettant de suivre son exécution et d'en apprécier les
            résultats… »
          </Typography>
        </div>
      </div>
    </div>
  );
}
