import axios from "axios";
import { ElementModel } from "../../models/Element.model";
import { plainToInstance } from "class-transformer";
import { ModuleModel, ModuleOrigin } from "../../models/Module.model";
import { ModuleSuggestionInterface } from "../../models/IModuleSuggestion.model";


export enum SuggestionType {
  Module = "modules",
  Skill = "skill",
  Goal = "goal",
  Evaluation = "evaluation",
  Asset = "asset",
  Modality = "modality",
  Public = "public",
  Prerequisite = "prerequisite",
}

export class SuggestionService {
  public static getSuggestions(
    type: SuggestionType,
    adapted: boolean,
    historic: boolean
  ): Promise<ModuleSuggestionInterface[]> {
    let suggestionUrl = "";
    switch (type) {
      case SuggestionType.Module:
        adapted && !historic ? (suggestionUrl = "adaptedModules") : (suggestionUrl = "modules");
        break;
      case SuggestionType.Skill:
        suggestionUrl = "skill";
        break;
      case SuggestionType.Goal:
        adapted && !historic ? (suggestionUrl = "adaptedGoal") : (suggestionUrl = "goal");
        break;
      case SuggestionType.Evaluation:
        suggestionUrl = "evaluation";
        break;
      case SuggestionType.Asset:
        suggestionUrl = "asset";
        break;
      case SuggestionType.Modality:
        suggestionUrl = "modality";
        break;
      case SuggestionType.Public:
        suggestionUrl = "public";
        break;
      case SuggestionType.Prerequisite:
        suggestionUrl = "prerequisite";
        break;
      default:
        console.log("No type found");
    }

    let urlAPI = "";
    if(historic) {
      urlAPI = process.env.REACT_APP_API_URL + "/historic/" + suggestionUrl;
    } else {
      urlAPI = process.env.REACT_APP_API_URL + "/suggestion/" + suggestionUrl;
    }
  
    return new Promise((resolve, reject) => {
      axios
        .get(urlAPI)
        .then((response) => {
          if (response.data) {
            let result:ModuleModel[] = []

            const origin = historic ? ModuleOrigin.HISTORIC : ModuleOrigin.SUGGESTION

            if (SuggestionType.Module === type) {
              result = plainToInstance(ModuleModel, response.data)

              // inject the origin to choose the proper way to add module from suggestion or historic when using drag and drop
              result.forEach((r:ModuleModel) => { r.origin =  origin }) 

               resolve(result);
            }else{       
               resolve(plainToInstance(ElementModel, response.data));
            }
            
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });


  }
}
