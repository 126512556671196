import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { RoleType, UserContext } from "../../context/user.context";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { ProgramContext } from "../../context/program.context";
import { TrainerModel } from "../../models/Trainer.model";
import { ProgramStatus } from "../../models/Program.model";

export default function ParticipantToolTip(props: {
  click: boolean;
  setClick: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [program] = useContext(ProgramContext);

  const AddTrainer = () => {
    return (
      <ListItem>
        <ListItemButton
          onClick={() => {
            props.setClick(true);
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText>Ajouter un participant</ListItemText>
        </ListItemButton>
      </ListItem>
    );
  };

  const [users, setUsers] = useState<
    { user: TrainerModel; confirm: boolean }[]
  >([]);

  useEffect(() => {

    // mainTrainer is expected to validate only in EDITION status
    let mainTrainerHaveConfirmed:boolean = program?.status == ProgramStatus.EDITION ? false : true;

    if (program) {
      const usersTmp: { user: TrainerModel; confirm: boolean }[] = [];
      usersTmp.push({
        user: program.mainTrainer,
        confirm: mainTrainerHaveConfirmed,
      });

      program.trainers.forEach((trainer) => {
        let confirm = true;
        program.notValidated.forEach((notValidated) => {
          if (notValidated._id === trainer._id) {
            confirm = false;
          }
        });
        usersTmp.push({
          user: trainer,
          confirm: confirm,
        });
      });
      setUsers(usersTmp);
    }
  }, [program]);

  const [currentUser] = useContext(UserContext);
  return (
    <List>
      {users.map((user) => {
        return (
          <ListItem key={user.user._id}>
            <ListItemIcon>
              {user.confirm ? <DoneIcon /> : <EditIcon />}
            </ListItemIcon>
            <ListItemText>
              {user.user.firstname} {user.user.lastname}
            </ListItemText>
          </ListItem>
        );
      })}
      {currentUser?.role === RoleType.ADMIN ? <AddTrainer /> : null}
    </List>
  );
}
