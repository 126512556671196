import "./TitleModuleTextField.css";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { ModuleModel } from "../../models/Module.model";
import { ModuleService } from "../../service/program/Module.service";

export function TitleModuleTextField(props: {
  firstLevel: boolean;
  placeholder: string;
  size: number;
  module: ModuleModel;
  parentModuleId?: string;
  setModule: Dispatch<SetStateAction<ModuleModel>>;
  handleFocus: React.FocusEventHandler<HTMLInputElement>;
}) {
  const [title, setTitle] = useState(props.module.title);

  const inputReference = useRef(null);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value);
  };

   useEffect(() => {
    
    if(props.module.shouldFocus == true ){      
      if(inputReference && inputReference.current){
        (inputReference.current as HTMLInputElement).focus();
      } 
    }

  }, [props.module.shouldFocus] ) 

  const handleElementChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (props.module._id.length > 5) {      
      // update the module title
      ModuleService.updateModuleTitle(
        props.module._id,
        event.currentTarget.value
      ).then((module) => {
        //update module from db
        props.setModule((module as any).module); // ?
      });

    } else {
      if (props.firstLevel) {
        // add module in db
        ModuleService.addModuleInTrainingProgram(
          event.currentTarget.value
        ).then((module) => {
          module.setTmpId(props.module._id)
          props.setModule(module);
        });
      } else {
        if (props.parentModuleId) {
          // add module in module
          ModuleService.addModuleInModule(
            props.parentModuleId,
            event.currentTarget.value
          ).then((newModule: ModuleModel) => {
            props.setModule(newModule);
          });
        }
      }
    }
  };

  return (
    <React.Fragment>
      <input
        ref={inputReference}         
        type="text"
        className={props.firstLevel ? "textfield titre2" : "textfield titre3"}
        placeholder={props.placeholder}
        id={props.module._id}
        value={title}
        onChange={handleChange}
        onFocus={props.handleFocus}
        onBlur={handleElementChange}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.currentTarget.blur();
          }
        }}
      />
    </React.Fragment>
  );
}

