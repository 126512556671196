import { Box, Grid, Stack } from "@mui/material";


export function Footer(){
    return (
        <Box sx={{
            width: "auto", color: "white", backgroundColor: "primary.main", 
            padding: "60px", margin: "0px", marginTop: "80px", fontSize: "0.9em"
            
            }}>
            <Stack>
                <Grid container>
                    <Grid item xs={3} container sx={{marginBottom: "70px", marginTop: "40px"}}>© 2022 Tous droits réservés</Grid>
                </Grid>

                <div style={{borderBottom: "1px darkgray solid", width: "100%"}}></div>
                
                <Grid container sx={{marginTop: "40px", marginBottom: "30px"}}>
                    <Grid item xs={3} sx={{textAlign: "left"}}>Made in France.</Grid>
                    <Grid item xs={3} sx={{textAlign: "center"}}>Mentions légales</Grid>
                    <Grid item xs={3} sx={{textAlign: "center"}}>Termes et conditions</Grid>
                    <Grid item xs={3} sx={{textAlign: "right"}}>Conformité RGPD</Grid>
                </Grid>
            </Stack>
        </Box>
    )
}