import React from "react";


export class GlobalDrag{

    constructor(){
        this.suggestionCardIsDragging = false;
        this.moduleFirstLevelSwapIsDragging = false;
    }

    public suggestionCardIsDragging:boolean = false;
    public moduleFirstLevelSwapIsDragging:boolean = false;
}

export const GlobalDragContext = React.createContext<
  [GlobalDrag, React.Dispatch<React.SetStateAction<GlobalDrag>>]
>([new GlobalDrag(), () => {}]);
