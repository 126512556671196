import { Box } from "@mui/material";
import { Section } from "../Section/Section";
import { Note } from "../Note/Note";
import { ProgramFooter } from "../ProgramFooter/ProgramFooter";
import "./Program.css";
import { ProgramModel } from "../../models/Program.model";
import { ProgramHeader } from "../ProgramHeader/ProgramHeader";
import { ModuleInAProgram } from "../ModuleInAProgram/ModuleInAProgram";
import React, { Dispatch, SetStateAction, useCallback, useContext, useState, useEffect } from "react";
import { ElementType } from "../../models/Element.model";
import {
  AssetsContext,
  TermsAndDeadlineForAccessContext,
  DisabilityAccessibilityContext,
  EvaluationContext,
  GoalsContext,
  ModalitiesContext,
  ModulesContext,
  PrerequisitesContext,
  PublicsContext,
} from "../../context/element.context";
import InfoToUser from "../../Component/InfoToUser/InfoToUser";

export function Program(props: {
  program: ProgramModel | null;
  setProgram: Dispatch<SetStateAction<ProgramModel | null>>;
}) {
  // TODO : l'importer directement dans les sous élements ou on en a besoin
  const [goals, setGoals] = useContext(GoalsContext);
  const [modalities, setModalities] = useContext(ModalitiesContext);
  const [publics, setPublics] = useContext(PublicsContext);
  const [prerequisites, setPrerequisites] = useContext(PrerequisitesContext);
  const [evaluation, setEvaluation] = useContext(EvaluationContext);
  const [assets, setAssets] = useContext(AssetsContext);
  const [termsAndDeadlineForAccess, setTermsAndDeadlineForAccess] = useContext(TermsAndDeadlineForAccessContext);
  const [disabilityAccessibility, setDisabilityAccessibility] = useContext(DisabilityAccessibilityContext);
  const [modules, setModules] = useContext(ModulesContext);
  const [numberOfModulesRemainingForValidation, setNumberOfModulesRemainingForValidation] = useState(-1);

  const refreshNumberOfModulesRemainingForValidation = useCallback(() => {
    if(props.program) {
      const nbrModules = modules.length;
      const nbrModulesRequis = Math.floor((props.program.hours.nbHours - 1) / 10) + 1;
      const nbrModuleRemaining = Math.max(0, nbrModulesRequis - nbrModules);
      setNumberOfModulesRemainingForValidation(nbrModuleRemaining);
    } else {
      setNumberOfModulesRemainingForValidation(-1);
    }
}, [props.program, modules]);

  useEffect(() => {
    refreshNumberOfModulesRemainingForValidation();
  }, [props.program, modules, refreshNumberOfModulesRemainingForValidation]);

  if (props.program) {
    return (
      <Box
        className="program"
        sx={{
          bgcolor: "info.light",
          borderRadius: "16px",
          marginRight: "20px",
          padding: "20px",
        }}
      >
        {/* Header */}
        <ProgramHeader
          title={props.program.title}
          topic={props.program.topic}
        />

        {/* program's sections */}
        <Section
          content={goals}
          title={"OBJECTIFS"}
          type={ElementType.GOAL}
          setProgram={props.setProgram}
          setContent={setGoals}
        />
        
        <ModuleInAProgram
          hours={props.program.hours}
          firstLevel={true}
          setProgram={props.setProgram}
          setModules={setModules}
          modules={modules}
        />

        <InfoToUser
          text={numberOfModulesRemainingForValidation === 0 ? "Assez de modules ont été ajouté, la validation est désormais possible" : "Il manque " + numberOfModulesRemainingForValidation + " modules pour pouvoir valider le programme"}
          type={numberOfModulesRemainingForValidation === 0 ? "success" : "warning"}
          closable={false}
        />
        
        <Section
          content={modalities}
          title={"MODALITÉS PÉDAGOGIQUES"}
          type={ElementType.MODALITY}
          setProgram={props.setProgram}
          setContent={setModalities}
        />
        <Section
          content={publics}
          title={"PUBLICS"}
          type={ElementType.PUBLIC}
          setProgram={props.setProgram}
          setContent={setPublics}
        />
        <Section
          content={prerequisites}
          title={"PRÉREQUIS"}
          type={ElementType.PREREQUISITE}
          setProgram={props.setProgram}
          setContent={setPrerequisites}
        />
        <Section
          content={evaluation}
          title={"SUIVI ET ÉVALUATION"}
          type={ElementType.EVALUATION}
          setProgram={props.setProgram}
          setContent={setEvaluation}
        />
        <Section
          content={assets}
          title={"LES ATOUTS"}
          type={ElementType.ASSET}
          setProgram={props.setProgram}
          setContent={setAssets}
        />
        <Section
          content={termsAndDeadlineForAccess}
          title={"MODALITES ET DELAI D'ACCÈS"}
          type={ElementType.TERMSANDDEADLINEFORACCESS}
          setProgram={props.setProgram}
          setContent={setTermsAndDeadlineForAccess}
        />
        <Section
          content={disabilityAccessibility}
          title={"ACCESSIBILITE HANDICAP :"}
          type={ElementType.DISABILITYACCESSIBILITY}
          setProgram={props.setProgram}
          setContent={setDisabilityAccessibility}
        />
        <Note />

        {/* Footer */}
        <ProgramFooter />
      </Box>
    );
  } else {
    return <></>;
  }
}
