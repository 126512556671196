import { Id, toast, ToastOptions } from 'react-toastify';

export enum NotificationType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARN = 'warn',
    INFO = 'info'
}

export const notify = (
    notificationType : NotificationType,
    message : string,
    options? : ToastOptions
    ): Id => {
        return toast[notificationType](message, options);
};


export const promise = (promise: Promise<any>, successMessage: string, errorMessage: string, pendingMessage: string) => {
    toast.promise(promise, {
        pending: pendingMessage,
        success: successMessage,
        error: errorMessage
        })
};