import { TrainerModel } from "./Trainer.model";
import { ModuleSuggestionInterface } from "./IModuleSuggestion.model";
import { Transform, TransformFnParams, Type } from "class-transformer";

export enum ElementType {
  GOAL = "Objectif",
  MODALITY = "Modalités Pédagogiques",
  PUBLIC = "Public",
  EVALUATION = "Évaluation",
  SKILL = "Compétences",
  PREREQUISITE = "Prérequis",
  ASSET = "Atouts",
  TERMSANDDEADLINEFORACCESS = "Modalités et délai d'accès",
  DISABILITYACCESSIBILITY = "Accessibilité aux personnes en situation de handicap",
  MODULE = "Module",
}
function transformElementType(type: TransformFnParams): ElementType {
  let typeString = type.value as string;
  switch (typeString) {
    case "GOAL":
      return ElementType.GOAL;
    case "MODALITY":
      return ElementType.MODALITY;
    case "PUBLIC":
      return ElementType.PUBLIC;
    case "EVALUATION":
      return ElementType.EVALUATION;
    case "SKILL":
      return ElementType.SKILL;
    case "PREREQUISITE":
      return ElementType.PREREQUISITE;
    case "ASSET":
      return ElementType.ASSET;
    case "TERMSANDDEADLINEFORACCESS":
      return ElementType.TERMSANDDEADLINEFORACCESS;
    case "DISABILITYACCESSIBILITY":
      return ElementType.DISABILITYACCESSIBILITY;
    default:
      return ElementType.GOAL;
  }
}

export class ElementModel implements ModuleSuggestionInterface {
  public _id: string;
  public text: string;
  @Type(() => TrainerModel)
  public writer: TrainerModel | null;

  @Transform(transformElementType)
  public type: ElementType;
  public isRequired: boolean;
  public model: string | null;
  constructor(
    _id: string,
    text: string,
    writer: TrainerModel | null = null,
    type: ElementType,
    isRequired: boolean,
    model: string | null = null
  ) {
    this._id = _id;
    this.text = text;
    this.writer = writer;
    this.type = type;
    this.isRequired = isRequired;
    this.model = model;
  }

  toString(): string {
    return this.text;
  }

  getType(): ElementType {
    return this.type;
  }

  search(search: string): boolean {
    return this.text.toLowerCase().includes(search.toLowerCase());
  }
}
