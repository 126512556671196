import "./ProgramHeader.css";
import logo from "../../assets/nouveau_logo.png";
import { Grid, Tooltip } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { ProgramContext } from "../../context/program.context";
import { ProgramService } from "../../service/program/Program.service";
import { RoleType, UserContext } from "../../context/user.context";
import { notify, NotificationType } from "../../utils/toaster";
import CreateIcon from '@mui/icons-material/Create';

export function ProgramHeader(props: { title: string; topic: string }) {
  const [program, setProgram] = useContext(ProgramContext);
  const [title, setTitle] = useState(props.title);
  const [user] = useContext(UserContext);
  var oldTitle = props.title;

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleTitleChange = (event: React.ChangeEvent<any>) => {
    if(oldTitle === event.target.value) return;
    if (program) {
      //program.updateTitle(event.currentTarget.value);
      ProgramService.updateTitleProgram(event.target.value)
      .then(() => {
        oldTitle = event.target.value
        notify(NotificationType.SUCCESS, "Titre mis à jour", { autoClose: 3000 });
      }).catch((error) => {
        setTitle(oldTitle)
        notify(NotificationType.ERROR, error.toJSON().message || "Erreur lors de la mise à jour du titre", { autoClose: 5000 });
      });
    }
    setProgram(program);
  };

  const toSentenceFormat = (str:string) => {
    const regex = /[^a-zA-Z0-9\sÀ-ÖØ-öø-ÿ']/g;
    var result = str.replace(regex, "");
    result = result.replace(/[\r\n]+/g, '');
    result = result.replace(/\s+/g, ' ');
    return result.charAt(0).toUpperCase() + result.slice(1);
}

  const changeHandler = (event: React.ChangeEvent<any>) => {
    setTitle(toSentenceFormat(event.target.value));
  };

  const handleIconClick = () => {
    if (textareaRef.current) {
      textareaRef.current.select();
    }
  };

  return (
    <div>
      <h1 className="program-title">Programme de formation</h1>
      <Grid container spacing={2}>
        {/* First space = empty */}
        <Grid item xs={2}></Grid>

        {/* Second space = titles */}
        <Grid item xs={8}>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }} >
              { (user && (user.role === RoleType.ADMIN || (user.role === RoleType.MAIN_TRAINER && program?.allowTitleChange === true)) ? (
                  <div className="container-program-title">
                    <textarea
                    id="program-title"
                    onBlur={handleTitleChange}
                    onChange={changeHandler}
                    value={title}
                    ref={textareaRef}
                  />
                  <CreateIcon 
                  className="program-title-icon-pen" 
                  fontSize="large"
                  onClick={handleIconClick} />
                </div>
              ) : (
                <Tooltip
                  title={
                    (program?.allowTitleChange === false ?
                      "Le titre du programme n'est pas modifiable" :
                      "Le titre du programme ne peut être modifié que par le formateur principal"
                    )
                  }
                  followCursor
                  enterDelay={1000}
                  enterNextDelay={1000}
                >
                  <textarea
                    id="program-title"
                    onBlur={handleTitleChange}
                    onChange={changeHandler}
                    value={title}
                    readOnly={true}
                  />
                </Tooltip>
              )
              )}
            </div>
          </div>
        </Grid>

        {/* Third space = Matchers logo*/}
        <Grid item xs={2}>
          <img src={logo} id="program-logo" alt="logo" />
        </Grid>
      </Grid>
    </div>
  );
}
