import React from "react";
import {ElementModel, ElementType} from "../models/Element.model";
import {ModuleModel} from "../models/Module.model";

export class ModuleSelected {
    public subModule: boolean;
    public id: string | null;
    public modules: ModuleModel[];
    public setModules: React.Dispatch<React.SetStateAction<ModuleModel[]>>;

    constructor(
        subModule: boolean,
        id: string | null = null,
        modules: ModuleModel[],
        setModules: React.Dispatch<React.SetStateAction<ModuleModel[]>>,
    ) {
        this.subModule = subModule;
        this.id = id;
        this.modules = modules;
        this.setModules = setModules;
    }
}

export class SkillSelected {
    public moduleId: string;
    public skills: ElementModel[];
    public setSkills: React.Dispatch<React.SetStateAction<ElementModel[]>>;

    constructor(
        moduleId: string,
        skills: ElementModel[],
        setSkills: React.Dispatch<React.SetStateAction<ElementModel[]>>,
    ) {
        this.moduleId = moduleId;
        this.skills = skills;
        this.setSkills = setSkills;
    }
}

export const TypeContext = React.createContext<[ElementType | ModuleSelected | SkillSelected, React.Dispatch<React.SetStateAction<ElementType | ModuleSelected | SkillSelected>>]>([ElementType.GOAL, () => {
}]);
