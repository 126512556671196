import React from "react";
import { ElementModel } from "../models/Element.model";
import { ModuleModel } from "../models/Module.model";

export const GoalsContext = React.createContext<
  [ElementModel[], React.Dispatch<React.SetStateAction<ElementModel[]>>]
>([[], () => {}]);

export const ModalitiesContext = React.createContext<
  [ElementModel[], React.Dispatch<React.SetStateAction<ElementModel[]>>]
>([[], () => {}]);

export const PublicsContext = React.createContext<
  [ElementModel[], React.Dispatch<React.SetStateAction<ElementModel[]>>]
>([[], () => {}]);

export const PrerequisitesContext = React.createContext<
  [ElementModel[], React.Dispatch<React.SetStateAction<ElementModel[]>>]
>([[], () => {}]);

export const AssetsContext = React.createContext<
  [ElementModel[], React.Dispatch<React.SetStateAction<ElementModel[]>>]
>([[], () => {}]);

export const TermsAndDeadlineForAccessContext = React.createContext<
  [ElementModel[], React.Dispatch<React.SetStateAction<ElementModel[]>>]
>([[], () => {}]);

export const DisabilityAccessibilityContext = React.createContext<
  [ElementModel[], React.Dispatch<React.SetStateAction<ElementModel[]>>]
>([[], () => {}]);

export const EvaluationContext = React.createContext<
  [ElementModel[], React.Dispatch<React.SetStateAction<ElementModel[]>>]
>([[], () => {}]);

export const ModulesContext = React.createContext<
  [ModuleModel[], React.Dispatch<React.SetStateAction<ModuleModel[]>>]
>([[], () => {}]);


export const SkillsContext = React.createContext<
  [ElementModel[], React.Dispatch<React.SetStateAction<ElementModel[]>>]
>([[], () => {}]);