import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { ProgramContext } from "./context/program.context";
import { RoleType, UserContext } from "./context/user.context";
import TrainerPage from "./page/trainerPage/trainerPage";
import ClientPage from "./page/clientPage/clientPage";
import MainTrainerPage from "./page/mainTrainerPage/mainTrainerPage";
import { useDragDropManager } from "react-dnd";

function App(props: {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  displayProgram: boolean | null;
  setDisplayProgram: Dispatch<SetStateAction<boolean | null>>;
}) {
  const [program, setProgram] = useContext(ProgramContext);
  const [user] = useContext(UserContext);

  const isLogged = user && program;

  /* react dnd : to scroll up/down during dragging process */
  const [dragValue, setDragValue] = useState<boolean>(false);
  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();
  const unsubscribeRef = useRef<any>();

  /* connect to global react DNC monitor to scroll the window up/down during dragging process */
  useEffect(() => {
    if (dragValue) {
      
      unsubscribeRef.current = monitor.subscribeToOffsetChange(() => {
        const offset = monitor.getClientOffset();
        const clientHeight = window.innerHeight

        if (!offset) return;

        if (offset.y < clientHeight/ 2 - 200) {
          window.scrollBy(0, -10)
        } else if (offset.y > clientHeight / 2 + 200) {
          window.scrollBy(0, 10)
        } else if (          
          offset.y > clientHeight/ 2 - 200 &&
          offset.y < clientHeight / 2 + 200
        ) {

        }
      });
    } else if (unsubscribeRef.current) {      
      unsubscribeRef.current();
    }
  }, [dragValue, monitor]);

  /* listen for react dnd monitor changing values */
  useEffect(() => {
    const unsubscribe = monitor.subscribeToStateChange(() => {
      if (monitor.isDragging()) setDragValue(() => true);
      else if (!monitor.isDragging()) setDragValue(() => false);
    });

    return () => {
      unsubscribe();
    };
  }, [monitor]);




  return (
    <React.Fragment>

      <Backdrop sx={{ color: "#fff", zIndex: 9001 }} open={props.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {isLogged && user.role === RoleType.MAIN_TRAINER &&
        <MainTrainerPage
          program={program}
          setProgram={setProgram}
          displayProgram={props.displayProgram}
          loading={props.loading}
          setDisplayProgram={props.setDisplayProgram}
          setLoading={props.setLoading}
        />
      }

      {isLogged && user.role === RoleType.TRAINER &&
        <TrainerPage
          program={program}
          setProgram={setProgram}
          displayProgram={props.displayProgram}
          loading={props.loading}
          setDisplayProgram={props.setDisplayProgram}
          setLoading={props.setLoading}
        />
      }

      {isLogged && user.role === RoleType.ADMIN &&
        <MainTrainerPage
          program={program}
          setProgram={setProgram}
          displayProgram={props.displayProgram}
          loading={props.loading}
          setDisplayProgram={props.setDisplayProgram}
          setLoading={props.setLoading}
        />
      }

      {isLogged && user.role === RoleType.CLIENT &&
        <ClientPage program={program} setProgram={setProgram} />
      }


    </React.Fragment>
  )



}

export default App;
