import React, { useContext, useEffect } from "react"
import { ProgramContext } from "../../../context/program.context";
import { UserContext } from "../../../context/user.context";
import { TrainerModel } from "../../../models/Trainer.model";

import { Button, Switch, Typography } from "@mui/material";
import { Box} from '@mui/material';
import { Debug, DebugContext, DebugContextData } from "../../../context/debugContext";

import { ProgramService } from "../../../service/program/Program.service";
interface CurrentUserDebugProps {

}

export const DevDebugTools = (props: CurrentUserDebugProps) => {

    const [debug, setDebugContext] = useContext(DebugContext);

    const [program, setProgram] = useContext(ProgramContext);
    const [user] = useContext(UserContext);

    const trainer: TrainerModel = user?.user as TrainerModel;

    const [debugStateDom, setDebugStateDom] = React.useState(debug.config?.debugDom);
    const [debugStateConsole, setDebugStateConsole] = React.useState(debug.config?.debug);



    const handleChangeDomDebug = (event: any) => {
        setDebugStateDom(event.target.checked);
        setDebugContext(new Debug(
            {
                displayDebugTool: debug.config?.displayDebugTool,
                debug: debug.config?.debug,
                debugDom: event.target.checked
            } as DebugContextData
        ))
    };

    const handleChangeDomConsole = (event: any) => {
        setDebugStateConsole(event.target.checked);
        setDebugContext(new Debug(
            {
                displayDebugTool: debug.config?.displayDebugTool,
                debug: event.target.checked,
                debugDom: debug.config?.debugDom,
            } as DebugContextData
        ))
    };


    if (!debug.config?.displayDebugTool) {
        return (<div></div>)
    }

    const generatePdf = () => {
        if (program?._id) {
            ProgramService.generatePdfAtBackend(program?._id).then((r: any) => {
                var file = new Blob([r], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
        }
    }

    if (user) {

        return (
            <Box style={{
                padding: 5,
                borderRadius: 5,
                backgroundColor: "lightblue",
                position: "fixed", bottom: 10, right: 10, zIndex: 1000
            }}>
                <Typography>rôle: {user?.role}</Typography>
                <Typography>{trainer?.firstname} {trainer?.lastname}</Typography>
                <Typography>userId: {trainer?._id}</Typography>

                {program &&
                    <>
                        <Typography style={{ marginTop: 10 }}>programId: {program._id}</Typography>
                        <Typography style={{ marginTop: 10 }}>program status: {program.status}</Typography>
                    </>
                }


                <Box sx={{ flexDirection: 'row', display: 'flex' }}>

                    <Button onClick={() => { generatePdf() }} variant="contained">PDF</Button>

                    <Box >
                        <small>dom</small>
                        <Switch
                            checked={debugStateDom}
                            onChange={handleChangeDomDebug}
                            name="debug"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Box>

                    <Box >
                        <small>console</small>
                        <Switch
                            checked={debugStateConsole}
                            onChange={handleChangeDomConsole}
                            name="console"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Box>
                </Box>
            </Box>
        )
    } else {
        return (null)
    }
};

export default DevDebugTools;
