import axios from "axios";
import { plainToInstance } from "class-transformer";
import { TopicModel } from "../../models/Topic.model";



export class TopicService {
    public static getAllTopics(): Promise<TopicModel[]> {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_API_URL + "/topic/")
                .then((response) => {
                    let topicJson = response.data;
                    let topics = plainToInstance(TopicModel, topicJson);
                    // @ts-ignore
                    resolve(topics);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    };
}