import {
  Box,
  Drawer,
  Fab,
  Stack,
  Switch,
  styled,
} from "@mui/material";
import { useState } from "react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import BiblioSuggestions from "../BiblioSuggestions/BiblioSuggestions";
import "./SuggestionField.css";

const drawerWidth = 355;

const DrawerButton = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: "hidden",
  backgroundColor: "primary.main!important",
  marginLeft: `-${drawerWidth - 5}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export default function SuggestionField({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
 

  const handleDrawer = () => {
    setOpen(!open);
  };
  
  return (
    <Box sx={{ display: "flex", marginRight: "45px" }}>
      <Drawer
        sx={{         
          position: "relative",
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            borderWidth: 0,
            backgroundColor: "white",
            overflow: "hidden!important"
          },
          zIndex: 5,
        }}
        
        style={{overflow: "hidden!important"}}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Stack
        
        sx={{/*  overflowX:"hidden", */ bgcolor: "primary.main", 
              color: "white", borderRadius: "0px 20px 0px 0px", 
              padding:"0 0 0 0", textAlign: "center", 
              top:"104px", position: "relative", 
              height:"100%", width:"100%"
            }}
        >


          <BiblioSuggestions />
          
        </Stack>
      </Drawer>

      {/* ----- DRAWER BUTTON ----- */}
      <DrawerButton open={open}>
        <div className="drawerButton">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Fab
              sx={{
                "&.MuiFab-root": {
                  paddingLeft: "6px",
                },
                backgroundColor: "#D93174"
              }}
              onClick={handleDrawer}
            >
              {open ? (
                <ArrowBackIos sx={{color: "white"}} />
              ) : (
                <ArrowForwardIos sx={{color: "white"}} />
              )}
            </Fab>
          </Box>
        </div>
      </DrawerButton>
    </Box>
  );
}
