import React from "react";

const CustomArrowIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <polygon
        style={{ stroke: "transparent", fill: "rgb(255, 255, 255)", paintOrder: "fill" }}
        points="100 0 200 0 400 250 200 500 100 500 300 250"
      />
    </svg>
  );
};

export default CustomArrowIcon;
