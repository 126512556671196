import "./TemplateChoice.css";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { ActionButton } from "../../../Component/ActionButton/ActionButton";
import Headers from "../../../Component/Headers/Headers";
import { TemplateCard } from "../../../Component/TemplateCard/TemplateCard";
import { TemplateModel, TemplateOrigin } from "../../../models/Template.model";
import { TemplateService } from "../../../service/template/Template.service";
import { TemplatePreview } from "../../../Component/TemplatePreview/TemplatePreview";
import { TemplateInterface } from "../../../models/ITemplate.model";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { TopicService } from "../../../service/template/Topic.service";
import { TopicModel } from "../../../models/Topic.model";
import fuzzysort from "fuzzysort";
import { usePrevious } from "../../../hooks/usePrevious";
import SearchIcon from '@mui/icons-material/Search';
import { ProgramStatus } from "../../../models/Program.model";

const ALL_THEMES = "Tous les thèmes"

//TODO: Voir comment faire pour corriger le warning du useEffect qui ne contient pas fuzzy_search dans ses dépendances 
//       sans modifier le comportement de la fonction (en outre la recherche de templates)
export function TemplateChoiceMainTrainer(props: {
  topic: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setDisplayProgram: Dispatch<SetStateAction<boolean | null>>;
}) {
  const [templates, setTemplates] = useState<TemplateModel[]>([]);
  const [backupTemplates, setBackupTemplates] = useState<TemplateModel[]>([]);
  const [open, setOpen] = useState(false);
  const [template, setTemplate] = useState<TemplateModel | null>(null);
  const [onlyHistoric, setOnlyHistoric] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  const [topic, setTopic] = useState<string>("");
  const [topics, setTopics] = useState<TopicModel[]>([]);

  //Hook created to get previous value of search
  const prevSearch = usePrevious(search);

  useEffect(() => {
    getData()
    setSearch("")
    setTopic(ALL_THEMES)
  }, [onlyHistoric])


  

  // get official templates
  const getTemplates = () => {
    TemplateService.getAllTemplates(TemplateOrigin.TEMPLATES).then((templates) => {
      setTemplates(templates);
      setBackupTemplates(templates)
    });
    TopicService.getAllTopics().then((topics) => {
      setTopics(topics);
    });
  }

  // get user templates in history - fill fakes topics according
  const getHistoric = () => {
    TemplateService.getAllTemplates(TemplateOrigin.HISTORIC).then((templates) => {
      setTemplates(templates);
      setBackupTemplates(templates)

      // forge fakes topics
      let fakeTopics: TopicModel[] = templates.map((t: TemplateModel, index) => {
        let to = new TopicModel(`topic_${index}`, t.topic)
        return to
      })

      var uniqueTopicsArray: string[] = [];
      for (var i = 0; i < fakeTopics.length; i++) {
        var currentTopic = fakeTopics[i].label;
        var isDuplicate = false;
        // check if the current topic already exists in the uniqueTopicsArray
        for (var j = 0; j < uniqueTopicsArray.length; j++) {
          if (uniqueTopicsArray[j] === currentTopic) {
            isDuplicate = true;
            break;
          }
        }

        // if the current topic is not a duplicate, add it to the uniqueTopicsArray
        if (!isDuplicate) {
          uniqueTopicsArray.push(currentTopic);
        }
      }

      fakeTopics = uniqueTopicsArray.map((t: string, index) => {
        let to = new TopicModel(`topic_${index}`, t)
        return to
      })

      setTopics(fakeTopics);



    });





  }

  // fetch the templates according the myHistoric selected or not
  const getData = () => {
    let origin: TemplateOrigin = onlyHistoric == true ? TemplateOrigin.HISTORIC : TemplateOrigin.TEMPLATES;
    const templates = (onlyHistoric == false) ? getTemplates() : getHistoric()
  }

  // first call
  useEffect(() => {
    getTemplates()
  }, []);

  const openPreview = (template: TemplateInterface | null) => {
    setTemplate(template);
    setOpen(true);
  };

  //Fuzzy search function
  const fuzzy_search = (templates_search: TemplateModel[], self_template: boolean, search: string) => {
    if (self_template) {
      templates_search = templates;
    }
    var results = fuzzysort.go(search, templates_search, { key: "title", limit: 100, threshold: -100000000000000000000000000000 });
    var newTemplates: TemplateModel[] = results.map((template) => template.obj);
    setTemplates(newTemplates);
  };

  // search inside ever topics filtered templates - if search string is < 2 || nil reset to backup templates
  const doSearch = () => {
    let origin: TemplateOrigin = onlyHistoric == true ? TemplateOrigin.HISTORIC : TemplateOrigin.TEMPLATES;

    if (search === "" || search.length < 2) {
      setTemplates(backupTemplates)
    } else {
      if (prevSearch !== undefined && prevSearch.length > search.length) {
        fuzzy_search(templates, false, search)
      } else {
        fuzzy_search([], true, search);
      }
    }
  }

  //To handle the search of templates
  useEffect(() => {
    doSearch()
  }, [search, topic, prevSearch, onlyHistoric]);

  
  // when topic changes, filted the templates according or reset if "all" is selected
  useEffect(() => {
    
    if(topic == ALL_THEMES){
      setTemplates(backupTemplates) // reset to all fetched templates
    }else{
      // filter from backuped templates
      let filteredTemplates:TemplateModel[] = backupTemplates.filter((t:TemplateModel) => t.topic == topic )        
      setTemplates(filteredTemplates)
    }
}, [topic])


  return (
    <div>
      <Headers showSave={null} status={ProgramStatus.NOT_STARTED} />
      <h1 style={{
        marginTop: "calc('90px' + '10px')", // header fixed height
        width: "100%",
        textAlign: "center",
        padding: "0",
      }}>Choix du modèle</h1>

      {/* --- Topic --- */}
      <Box
        sx={{
          color: "primary.main",
          ml: "200px",
          mr: "200px",
          textAlign: "center",
        }}
      >
      </Box>
      <div className="topDiv">
        <h2 style={{ "color": '#1101a2', textAlign: "center" }}> Choisissez votre modèle du programme de formation : </h2>
        <div id="containerSearchVierge">
          <div className="searchDiv" /* style={{display: onlyHistoric == true ? "none" : "block"}} */ >
            <div className="cardHeader">
              A partir d'un modèle existant :
            </div>

            <Grid container spacing={2}>
              <Grid item xs={7}>
                <FormControl sx={{ width: "90%" }}>
                  <InputLabel id="choice-template-label">Thématique</InputLabel>

                  <Select
                    labelId="choice-template-label"
                    label="Tous les thèmes"
                    value={topic}
                    placeholder="Thème du programme"
                    onChange={(e) => setTopic(e.target.value)}
                  >
                    <MenuItem value={ALL_THEMES}>Tous les thèmes</MenuItem>

                    {topics && topics.map((topic) => (
                      <MenuItem key={topic._id} value={topic.label}>{topic.label}</MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={5}>
                <TextField
                  variant="standard"
                  sx={{ width: "90%", height: "30px", borderRadius: "10px", border: "1px grey solid", padding: "10px" }}
                  placeholder="saissisez un mot clé"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <SearchIcon
                        sx={{
                          borderRadius: '50%',
                          color: 'white',
                          backgroundColor: '#D93174',
                        }}
                        fontSize="small"
                      />
                    ),
                  }} />
              </Grid>
            </Grid>

          </div>
          <div className="viergeDiv">
            <div className="cardHeader">
              A partir d'un modèle vierge :
            </div>
            <ActionButton
              label={"download"}
              text={"Modèle vierge"}
              icon={AddCircleOutlineRoundedIcon}
              onClick={() => openPreview(null)}
              disable={false}
            />
          </div>
        </div>
      </div>

      <div className="lastDiv">
        <div id="topDivOfLastDiv">
          <div id="biblioDiv">
            <h3>
              LA BIBLIOTHÈQUE MATCHERS
            </h3>
          </div>
          <div id="rightDiv">
            <Switch
              checked={onlyHistoric}
              onChange={() => setOnlyHistoric(!onlyHistoric)}
              sx={{ color: "white" }}
              color="secondary"
            />
            <h3>
              Seulement ma bibliothèque
            </h3>
          </div>
        </div>
        <div className="template">
          {templates?.length == 0 ?
            (
              <div style={{ width: "100%", textAlign: "center" }}>Aucun résultat. Essayez autre chose ou lancez-vous !</div>
            )
            :
            (templates.map((template) => (
              <TemplateCard
                title={template.title}
                template={template}
                handleClick={openPreview}
                key={template._id}
                specialBorder={true}
                displayTopicAndHour={true}
              />)
            ))
          }
        </div>
      </div>

      {/* --- The template preview --- */}
      <TemplatePreview
        open={open}
        setOpen={setOpen}
        setTemplate={setTemplate}
        template={template}
        setLoading={props.setLoading}
        setDisplayProgram={props.setDisplayProgram}
      />
    </div>
  );
}
