import Button from "@mui/material/Button";
import { Dispatch, SetStateAction } from "react";
import { ElementModel, ElementType } from "../../models/Element.model";
import { ModuleModel } from "../../models/Module.model";
import { Id, ToastItem, toast } from "react-toastify";
import { ElementService } from "../../service/program/Element.service";
import { ModuleService } from "../../service/program/Module.service";

export default class DeletionAlert {
  
  private elem: (ElementModel | ModuleModel | null) = null;
  private need_to_cancel_delete: boolean = false;
  private toastId: Id = 0;
  private firstLevel: boolean = false;


  /**
   * It's the component that will be displayed in the toast to cancel the delete of an element
   */
  Undo = ({closeToast} : {closeToast: any}) => {
    const handleClick = () => {
      this.need_to_cancel_delete = true;
      this.elem = null;
      closeToast();
    }

    return (
      <div>
        <div style={{ textDecoration: "none", color: "#7066C7", fontSize: "0.9em" }}>L'élément va être supprimé</div>
        <div style={{textAlign: "right"}}>
          <Button size="small" sx={{color: "white", backgroundColor: "#D93174", textTransform: "capitalize"}} onClick={handleClick}>
            Annuler
          </Button>
        </div>
      </div>
    )
  }


  deleteInDB = async () => {
    if (this.elem && this.elem instanceof ElementModel) {
      if (this.elem._id.length > 5) {
        switch (this.elem.type) {
          case ElementType.GOAL:
            await ElementService.deleteGoal(this.elem._id);
            break;
          case ElementType.ASSET:
            await ElementService.deleteAsset(this.elem._id);
            break;
          case ElementType.TERMSANDDEADLINEFORACCESS:
            await ElementService.deleteTermsAndDeadlineForAccess(this.elem._id);
            break;
          case ElementType.DISABILITYACCESSIBILITY:
            await ElementService.deleteDisabilityAccessibility(this.elem._id);
            break;
          case ElementType.EVALUATION:
            await ElementService.deleteEvaluation(this.elem._id);
            break;
          case ElementType.PREREQUISITE:
            await ElementService.deletePrerequisite(this.elem._id);
            break;
          case ElementType.PUBLIC:
            await ElementService.deletePublic(this.elem._id);
            break;
          case ElementType.MODALITY:
            await ElementService.deleteModality(this.elem._id);
            break;
          case ElementType.SKILL:
            await ElementService.deleteSkill(this.elem._id);
        }
      }
    } else if (
      this.elem &&
      this.elem instanceof ModuleModel
    ) {
      if (this.elem._id.length > 5) {
        if (this.firstLevel) {
          await ModuleService.deleteModuleOfTrainingProgram(this.elem._id);
        } else {
          await ModuleService.deleteModuleOfModule(this.elem._id);
        }
      }
    }
  };


  /**
   * This function will display a toast to confirm the deletion of an element
    * @param element The element to delete
    * @param setContent The function to update the content of the section that contain the element or the module
    * @param index The index of the element in the section
    * @returns
    * @constructor
    * @param element
    * @param setContent
    * @param index
    * @param firstLevel to know if the module is a first level module (parent module)
    */
  constructor(element: ElementModel | ModuleModel, setContent: Dispatch<SetStateAction<(ElementModel | ModuleModel)[]>>, index: number, firstLevel: boolean = false) {
    this.elem = element;
    this.firstLevel = firstLevel;
    this.toastId = toast(<this.Undo closeToast={toast.dismiss} />, { autoClose: 4000, toastId: this.elem._id, type: toast.TYPE.WARNING});
    toast.onChange((payload: ToastItem) => {
      if(payload.id === element._id && payload.status === "removed"){
        if(this.elem){
          this.deleteInDB();
          this.elem = null;
        }
        else{
          if(this.need_to_cancel_delete){
            this.need_to_cancel_delete = false;
            setContent((previousState) => {
              let copy = [...previousState];
              copy.splice(index, 0, element);
              return copy;
            })
          }
        }
      }
    })
  }

}