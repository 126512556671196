import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useState } from "react"
import { ActionButton } from "../ActionButton/ActionButton";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface ICommentDialogConfiguration{
    dialogTitle?:string;
    dialogExplainatation?:string;
    buttonWithFilledComment:string;
    buttonWithEmptyComment:string;
    mandatoryComment:boolean
}

interface DialogCommentProps {
    open: boolean;
    onClose: () => void
    onInvalidate: (comment: any | undefined) => void;
    configuration:ICommentDialogConfiguration
}


export const DialogComment = (props: DialogCommentProps) => {

    const [commentStr, setCommentStr] = useState<string | undefined>(undefined);

    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {

            }}
            aria-describedby="alert-dialog-slide-description"
        >
            {/* The title should have the main color of mui theme */}
            <DialogTitle sx={{ color: 'primary.main' }}>
            {props.configuration.dialogTitle}
            </DialogTitle>


            <DialogContent style={{ position: "relative" }}>
                <DialogContentText id="alert-dialog-slide-description">
                    {props.configuration.dialogExplainatation}
                </DialogContentText>

                <div style={{ margin: 20, marginTop: 40, maxWidth: "100%", position: "relative" }}>
                    <TextField
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCommentStr(event.target.value);
                        }}
                        fullWidth
                        id="outlined-multiline-static"
                        label={props.configuration.mandatoryComment == true ?  "Commentaire obligatoire" : "Commentaire optionnel" }
                        multiline
                        rows={4}

                    />
                </div>

            </DialogContent>


            <DialogActions style={{flexDirection: "row", justifyContent: "flex-end", display: "flex"}}>

                <Button onClick={props.onClose}>Annuler</Button>
                <ActionButton

                    disable={props.configuration.mandatoryComment == true && (commentStr == undefined || commentStr.length == 0 )}

                    onClick={() => {
                        props.onInvalidate(commentStr)
                    }}

                    text={commentStr == undefined || commentStr.length == 0 ? props.configuration.buttonWithEmptyComment : props.configuration.buttonWithFilledComment }
                    label={"Invalider"} /* label not realy reliable */
                    emptyColor={true}
                    style={{ }}
                />

            </DialogActions>
        </Dialog>
    )
};


DialogComment.defaultProps = {
    open: false,
    configuration:{
        dialogTitle: "Invalidation du programme",
        dialogExplainatation: "Vous pouvez ajouter un commentaire afin d'expliquer l'invalidation aux formateurs",
        buttonWithFilledComment: "Invalider avec commentaire",
        buttonWithEmptyComment: "Invalider sans commentaire",
        mandatoryComment:false
    }
}

export default DialogComment;
