import { ModuleModel } from "../../models/Module.model";
import "./ModuleInAProgram.css";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ProgramModel } from "../../models/Program.model";
import { Module } from "../Module/Module";
import { ModuleSelected, TypeContext } from "../../context/type.context";
import { ModuleService } from "../../service/program/Module.service";
import { useDrag, useDrop } from "react-dnd";
import { ElementType } from "../../models/Element.model";
import { notify, NotificationType } from "../../utils/toaster";
import { GlobalDragContext } from "../../context/globalDrag.context";
import { DebugContext } from "../../context/debugContext";

import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';

export function ModuleInAProgram(props: {
  hours: { nbHours: number; fixed: boolean };
  firstLevel: boolean;
  modules: ModuleModel[];
  path?: number[];
  setProgram: Dispatch<SetStateAction<ProgramModel | null>>;
  setModules: Dispatch<SetStateAction<ModuleModel[]>>;
  parentModule?: string;
}): JSX.Element {

  const [debug, ] = useContext(DebugContext);

  const [selectedType, setSelectedType] = useContext(TypeContext);
  const ErrorDisplay = useCallback((err: any) => {
    switch (err.status) {
      case 500:
        notify(NotificationType.ERROR, err.data.error || err.statusText);
        break;
      case 400:
        notify(NotificationType.ERROR, err.data.error || err.statusText);
        break;
      default:
        notify(NotificationType.ERROR, err.statusText);
    }
  }, []);

  const add = () => {    
    const id = props.modules.length;
    let newModule = new ModuleModel(
      id.toString(),
      "",
      [],
      [],
      null,
      null,
      props.modules.length + 1
    );
    newModule.shouldFocus = true
    props.setModules([...props.modules, newModule])
  };


  const handleFocus = () => {
    // TODO : revoir model
    setSelectedType(
      new ModuleSelected(false, null, props.modules, props.setModules)
    );
  };
  const [savedModules, setSavedModules] = useState(props.modules);

  const [globalDrag, setGlobalDrag] = useContext(GlobalDragContext);


/* when swapping root modules */
  const swapObject = useCallback(
    (item: ModuleModel, item2: ModuleModel, requestAPI: boolean) => {
      let copy = [...props.modules];

      const index = copy.findIndex((m: ModuleModel) => m._id == item._id)
      const index2 = copy.findIndex((m: ModuleModel) => m._id == item2._id)

      if(debug.config?.debug){
        console.log(`index of item   -----------> ${index} item ${item}`)
        console.log(`index of item2  -----------> ${index2} item ${item2}`)
      }

      if (index == -1 || index2 == -1) {
        console.error("swap items not found when swapping")
      }

      copy[index] = item2;
      copy[index2] = item;

      // wait for promise return ?
      props.setModules(copy);

      if(debug.config?.debug){
        console.log("[after swap] => ", copy.map((m: ModuleModel) => m.title))
        console.log("after request api", requestAPI)
      }

      if (requestAPI) {
        ModuleService.updateModuleOrderInProgram(copy)
          .then(() => {
            setSavedModules(copy);
          })
          .catch((e) => {
            ErrorDisplay(e);
            props.setModules(savedModules);
          });
      }



    },
    [ErrorDisplay, props, savedModules]
  );


  const addModuleFromSugeestionBar = (module: ModuleModel) => {
    ModuleService.addModuleInTrainingProgramFromSuggestion(module._id, module.origin)
      .then((module) => {
        props.setModules((previousState) => {
          return [...previousState, module];
        });
      })
      .catch(ErrorDisplay);
  };


  const [{ suggestionItem, canDrop, isOverCurrent, isOver }, drop] = useDrop(() => ({
    accept: ElementType.MODULE,
    drop: (item: ModuleModel, monitor) => {

      globalDrag.suggestionCardIsDragging = false;
      setGlobalDrag(globalDrag)

      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }

      if(!isOver){
        addModuleFromSugeestionBar(item);
      }
    },
    collect: (monitor) => ({
      suggestionItem: monitor.getItem() ,
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  }));




  let dropClass = "";

  if (isOverCurrent) {
    dropClass = "drop-btn drop-btn-hover";
  } else if (canDrop) {
    dropClass = "drop-btn  drop-btn-waiting";
  }

  return (
    <div>
      <h2 id="title" onClick={handleFocus}>
        Contenu
      </h2>
      <div >
        <div id="module-container"
        /*   className={
            selectedType instanceof ModuleSelected && selectedType.id === null
              ? "border-active "
              : "border "
          } */
        
        >
          {props.modules.map((moduleFirstLevel, index) => {
            return (
              <div className="" key={moduleFirstLevel._id}>
               
                <Module
                  firstLevel={true}
                  module={moduleFirstLevel}
                  modules={props.modules}
                  modules_firstLevel={props.modules}
                  setModules={props.setModules}
                  parentModule={moduleFirstLevel._id}
                  index={index}
                  swapObject={swapObject}
                />
              </div>
            );
          })}

          {/* {selectedType instanceof ModuleSelected &&
            selectedType.id === null && (
              <div className="alinea help-add-module">
                Cliquez sur une suggestion et ajoutez un module au contenu
              </div>
            )}
 */}
          <button ref={drop} className={`alinea ${dropClass}`} id="add-module" onClick={add}>
            
            {globalDrag.suggestionCardIsDragging == false &&
              <span>+ Ajouter <span className="bold">un module</span></span>
            }

            {globalDrag.suggestionCardIsDragging == true && suggestionItem?.getType() == ElementType.MODULE &&
              <div style={{flexDirection: "column", alignItems: "center", alignContent: "center",  display: "flex"}}>
              {isOverCurrent == false ? <HighlightAltIcon></HighlightAltIcon> : <DoneOutlineIcon></DoneOutlineIcon>}
              <span style={{marginTop: 20}}>Ajouter en tant que module principal</span>
              </div>
            }

          </button>

          
        </div>




      </div>
    </div>
  );
}
