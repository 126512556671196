import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Paper } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

export function SearchBar({
  search,
  setSearch,
}: {
  search: string;
  setSearch: (search: string) => void;
}) {
  return (
    <div>
      <Paper
        sx={{
          p: "2px 4px",
          m: "auto",
          mt: "25px",
          display: "flex",
          width: 320,
          borderRadius: "10px",
          marginBottom: "20px",
        }}
      >
        <SearchIcon sx={{ p: "10px" }} color="primary" />
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Rechercher par mot-clé"
          inputProps={{ "aria-label": "recherche" }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button onClick={() => setSearch("")}>
          <ClearIcon sx={{ color: "primary" }} />
        </Button>
        
      </Paper>
    </div>
  );
}
