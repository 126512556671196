import Headers from "../../Component/Headers/Headers";
import { ProgramModel, ProgramStatus } from "../../models/Program.model";
import { Box, Stack } from "@mui/material";
import InfoToUser from "../../Component/InfoToUser/InfoToUser";
import { Status } from "../../Component/Status/Status";
import { PdfDocument } from "../../Component/PdfDocument/PdfDocument";
import { ActionButton } from "../../Component/ActionButton/ActionButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React, { useState } from "react";
import { ProgramService } from "../../service/program/Program.service";
import { PdfService } from "../../service/pdf/Pdf.service";
import { NotificationType, notify } from "../../utils/toaster";
import DialogComment, { ICommentDialogConfiguration } from "../../Component/DialogComment/DialogComment";

export default function ClientPage(props: {
  program: ProgramModel;
  setProgram: (program: ProgramModel) => void;
}) {

  const [showCommentDialog, setShowCommentDialog] = useState(false);
  const [commentConfiguration, setCommentConfiguration] = useState<ICommentDialogConfiguration|undefined>(undefined);
  const [commentCallBack, setCommentCallback] = useState<  ((comment?:string) => void) | undefined>(undefined);
  

  const onProgramInvalidated = (comment:string|undefined) => {
    setShowCommentDialog(false)
  }

  const onClientValidateProgram = (comment: string | undefined = undefined) => {

    ProgramService.clientValidation(comment)
      .then((returnProgram: ProgramModel) => {
        props.setProgram(returnProgram);
        notify(NotificationType.SUCCESS, "Le programme a été validé avec succès", { autoClose: 10000 })
        window.location.reload();
      })
      .catch((error) => {
        notify(NotificationType.ERROR, "Une erreur est survenue lors de la validation du programme", { autoClose: 10000 })
      });

  }

  const onClientInvalidateProgram = (comment: string | undefined) => {
    if (!comment) {
      throw 'comment is undefined - should never happen'
    }

    ProgramService.clientInvalidation(comment)
    .then((returnProgram: ProgramModel) => {
      props.setProgram(returnProgram);
      notify(NotificationType.INFO, "Vous avez invalidé le programme", { autoClose: 10000 })
      setShowCommentDialog(false)
    })
    .catch((error) => {
      notify(NotificationType.ERROR, "Une erreur est survenue lors de l'invalidation du programme", { autoClose: 10000 })
    });


  }





  return (
    <div>
      <Headers
        showSave={null}
        status={props.program.status}
      />

      <h1
        style={{
          padding: "0",
          textAlign: "center",
        }}
      >
        {
          props.program.status === ProgramStatus.NOT_STARTED
            ? "Programme inacessible"
            : "Visualisation du programme"
        }
      </h1>

      <Box>
        {/* If the program is not started */}
        {props.program && props.program.status === ProgramStatus.NOT_STARTED ? (
          // Information
          <InfoToUser
            text={
              "L'édition du programme n'a pas commencé, vous serez notifié par mail lorsque vous pourrez accéder au document."
            }
            type={"warning"}
          />
        ) : (
          // If the program is already started
          <div>
            {/* Information to display to the client */}
            { props.program.status === ProgramStatus.CLIENT_VALIDATION
              ? <InfoToUser
                  text={
                      "La rédaction du programme a été finalisée par les formateurs. Vous pouvez maintenant valider le programme, ou l'invalider pour demander des modifications"
                  }
                  type={"success"}
                />
              : props.program.status === ProgramStatus.VALIDATED
              ? <InfoToUser
                  text={"Vous avez validé le programme. L'équipe Matchers vous souhaite une bonne continuation !"}
                  type={"success"}
                />
              : <InfoToUser
                  text={
                    "Le programme est en cours de rédaction, vous serez notifié lorsqu'il sera terminé."
                  }
                  type={"warning"}
                />
            }

            {/* ---- STATUS OF THE PROGRAM ---- */}
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={2}
            >

              { props.program.status === ProgramStatus.VALIDATED &&
                    <ActionButton
                    label={"download"}
                    text={"Télécharger (PDF)"}
                    icon={FileDownloadIcon}
                    onClick={() =>   ProgramService.openPdfInTab(props.program._id) }
                    disable={false}
                  />
              }

              
              { props.program.status === ProgramStatus.CLIENT_VALIDATION &&
                <>
                  <ActionButton
                    label={"validate"}
                    text={"Valider le programme"}
                    icon={null}
                    emptyColor={false}
                    onClick={() => {

                      setCommentConfiguration({
                        dialogTitle: "Validation du programme",
                        dialogExplainatation: "Vous pouvez ajouter un commentaire si nécessaire",
                        buttonWithEmptyComment: "Finaliser et télécharger le PDF",
                        buttonWithFilledComment: "Soumettre votre commentaire"
                      } as ICommentDialogConfiguration)

                      setCommentCallback(() => onClientValidateProgram)

                      setShowCommentDialog(true)

                    }}
                  />
                  <ActionButton
                    label={"invalidate"}
                    text={"Demander des modifications avant validation"}
                    icon={null}
                    emptyColor={true}
                    onClick={() => {
                      
                      setCommentConfiguration({
                        dialogTitle: "Demande de modifications du programme",
                        dialogExplainatation: "Vous devez ajouter un commentaire pour expliquer votre demande",
                        buttonWithEmptyComment: "Soumettre votre commentaire à Matchers",
                        buttonWithFilledComment: "Soumettre votre commentaire à Matchers",
                        mandatoryComment: true
                      } as ICommentDialogConfiguration)

                      setCommentCallback(() => onClientInvalidateProgram)

                      setShowCommentDialog(true)

                    }}
                  />
                </>
              }



              <Status status={props.program.status}></Status>
            </Stack>

            {/* Display PDF document */}
            <Box
              className="program"
              sx={{
                bgcolor: "info.light",
                borderRadius: "16px",
                marginLeft: "40px",
                marginRight: "40px",
                padding: "20px",
              }}
            >
              <PdfDocument hidden={false} />
            </Box>

            {/* Download PDF button */}
            <Box sx={{ marginLeft: "40px" }}>
            { props.program.status == ProgramStatus.VALIDATED &&
              <ActionButton
              label={"download"}
              text={"Télécharger (PDF)"}
              icon={FileDownloadIcon}
              onClick={() =>   ProgramService.openPdfInTab(props.program._id) }
              disable={false}
            />
            }
            </Box>
          </div>
        )}
      </Box>



      <DialogComment configuration={commentConfiguration} open={showCommentDialog} onClose={() => setShowCommentDialog(false)} onInvalidate={(comment:string|undefined) => {
        if(commentCallBack){
          commentCallBack(comment)
        }
        
        }}></DialogComment>

    </div>
  );
}
