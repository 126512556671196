import { Grid } from "@mui/material";
import logo from "../../assets/logo-matchers.png";
import "./Headers.css";
import { FilAriane, getPageFromProgramStatus } from "../FilAriane/FilAriane";
import { ProgramStatus } from "../../models/Program.model";

function Headers(props: { showSave: boolean | null; status: ProgramStatus | null }) {
  return (
    <Grid spacing={2} className="Headers" container>
      <Grid container item xs={2} justifyContent="center" sx={{padding: 0, margin: 0}}>
        <img src={logo} className="Headers-logo" alt="logo" />
      </Grid>
      {props.status !== null ? (
        <FilAriane
          CurrentPage={
            getPageFromProgramStatus(props.status as ProgramStatus)
          }
          style={{
            top: "89px",
          }}
        />
      ): (null)
      }
    </Grid>
  );
}
export default Headers;
