import axios from "axios";
import { plainToInstance } from "class-transformer";
import { TemplateModel, TemplateOrigin } from "../../models/Template.model";
import { ModuleOrigin } from "../../models/Module.model";

export class TemplateService {

  /**
   * Get all the templates from the DB that have the same topic
   * @param topic the topic we want to get all the templates to have
   * @returns the list of templates
   */
  static getTemplatesByTopic(topic: string): Promise<TemplateModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/template/topic/" + topic)
        .then((response) => {
          let templateJson = response.data;
          let templates = plainToInstance(TemplateModel, templateJson);
          // @ts-ignore
          resolve(templates);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  /**
   * Retrieves a template from the DB with its id
   * @param data
   * @returns the template
   */
  public getTemplate(id: string): Promise<TemplateModel> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/template", {
          params: { id: id },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Retrieves all the templates from the DB or all the user's program that is has written
   * @returns the list of templates
   */
  public static getAllTemplates(origin:TemplateOrigin) : Promise<TemplateModel[]> {
    
    let apiUri = origin == TemplateOrigin.TEMPLATES ? "/template/getAll" : "/training-program/oldPrograms"
        
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + apiUri)
        .then((response) => {
          let template = response.data;

          let templates:TemplateModel[] = [];

          if(origin == TemplateOrigin.TEMPLATES){
            templates = plainToInstance(TemplateModel, template.templates);
          }else if (origin == TemplateOrigin.HISTORIC) {
            templates = plainToInstance(TemplateModel, template);
          }

          // @ts-ignore
          resolve(templates);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Retrieves all the templates that have the same topic in the DB
   * @param topic the topic we want to get all the templates to have
   * @returns the list of similar templates
   */
  public static getSimilarTemplates(): Promise<TemplateModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/template/getSimilar")
        .then((response) => {
          let templateJson = response.data;
          let templates = plainToInstance(TemplateModel, templateJson);
          // @ts-ignore
          resolve(templates);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //delete
  public static delete(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/template/delete", {
          params: { id: id },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //update
  public update(id: string, title: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/template/update", {
          params: { id: id, title: title },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
