import { Grid } from "@mui/material";
import Headers from "../../Component/Headers/Headers";
import "./homePage.css"
import { ActionButton } from "../../Component/ActionButton/ActionButton";
import { Footer } from "../../Component/Footer/Footer";


//TODO: Lorsqu'il clique sur Commencez votre programme, il doit être redirigé vers la page de création de programme

export default function HomePage(props : {setDisplayHomePage: React.Dispatch<React.SetStateAction<boolean>>}) {

    const etapes: String[] = [
        /*"Consultez vos paramètres",*/
        "Choisissez votre modèle de programme",
        "Editez votre programme de formation",
        "Faites valider votre programme"
    ];

    return (
        <div style={{color: "#1101a2", width:"99vw", overflowX: "hidden"}}>
            <Headers
                showSave={null}
                status={null}
            />

            <div className={"top_page"}></div>

            <div className={"body_page"}>
                <h1 className={"title_home"}>Concevez vos programmes de<br/> formation sur-mesure</h1>


                <Grid sx={{paddingLeft: "5vw", paddingRight: "5vw"}} container>
                    <Grid item xs={5}>
                        <h3 className={"under_title"}><b>Matchers</b> vous accompagne tout au long de la<br/> construction de votre formation !</h3>
                        
                        <ol className={"home_list"}>
                            {etapes.map((etape, index) => {
                                return (
                                    <li key={index} style={{marginBottom: "15px"}}>{etape}</li>
                                )
                            })}
                        </ol>

                        
                        <ActionButton 
                            label={"start"} 
                            text={"Commencez votre programme"} 
                            disable={false} 
                            onClick={() => props.setDisplayHomePage(false)} 
                        />

                    </Grid>

                    <Grid item xs={7}>
                        <img className="main_page"
                            src={"https://ucarecdn.com/3fbead73-1bf1-4a7b-b465-f3abdf3d8920/-/quality/smart/-/format/auto/-/stretch/off/-/resize/623x/"} 
                            alt="Illustration de femme qui travaille sur son ordinateur" 
                        />
                    </Grid>
                </Grid>
            </div>

            <Footer />
        </div>
    )
}