import { Box, Fab } from "@mui/material";
import React from "react";

interface ActionButtonProps {
  label: string;
  text: string;
  icon?: any;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disable?: boolean;
  emptyColor?: boolean;
  style?: React.CSSProperties;
}

export function ActionButton(props: ActionButtonProps) {
  const { label, text, icon: Icon, onClick, disable, emptyColor, style } = props;

  const fabStyles = {
    textTransform: "none",
    borderRadius: "4px",
    color: emptyColor ? "#D93174" : "#fff",
    borderColor: disable ? "#a4a4a4" : "#D93174",
    borderWidth: "2px",
    borderStyle: "solid",
    backgroundColor: disable
      ? "#dec8d8"
      : emptyColor
      ? "#fff"
      : "#D93174",
    "&:hover": {
      backgroundColor: emptyColor ? "#D93174" : "#D93174",
      color: "#fff",
      borderColor: "#fff",
    },
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        p: 2,
        paddingLeft: "5px",
      }}
      style={style}
    >
      <Fab
        sx={fabStyles}
        disabled={disable}
        variant="extended"
        size="medium"
        aria-label={label}
        onClick={onClick}
      >
        {/* Button's text and icon*/}
        {text}
        { Icon ? <Icon sx={{ m: 1, color: "inherit" }} /> : null}
      </Fab>
    </Box>
  );
}