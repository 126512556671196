import { Box, Card, CardContent } from "@mui/material";
import { TemplateModel } from "../../models/Template.model";
import { TemplateInterface } from "../../models/ITemplate.model";
import borderImage from "../../assets/border.png";


export function TemplateCard(props: {
  title: string;
  template: TemplateInterface | null;
  handleClick: (template: TemplateModel | null) => void;
  specialBorder?: boolean;
  displayTopicAndHour?: boolean;
}) {
  function generateCardContentStyle() {
    let baseStyle = {
      fontSize: 16,
      textAlign: "center",
      backgroundColor: "white",
      width: "200px",
      height: "90px",
      margin: "10px auto auto auto",
      borderRadius: "10px",
    };
  
    if (props.specialBorder) {
      return baseStyle;
    }
  
    return {
      ...baseStyle,
      "&:after": {
        content: '""',
        position: "absolute",
        width: "100%",
        transform: "scaleX(0)",
        height: "2px",
        bottom: "0",
        left: "0",
        backgroundColor: "secondary.main",
        transformOrigin: "bottom right",
        transition: "transform 0.25s ease-out",
      },
      ":hover:after": {
        transform: "scaleX(1)",
        transformOrigin: "bottom left",
      },
    };
  }

  
  return (
      <Box
        className="card"
        sx={{
          display: "inline-block",
          margin: "10px",
        }}
      >
        <Card
          sx={{
            width: "250px",
            height: "150px",
            borderRadius: "10px",
          }}
          style={
            props.specialBorder
              ? {
                backgroundImage: `url(${borderImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                }
              : {}
          }
        >
          <CardContent
            onClick={() => {
              props.handleClick(props.template);
            }}
            sx={generateCardContentStyle()}
          >
            <Box style={{ verticalAlign: "middle" }}> {props.title} </Box>
          </CardContent>
        </Card>
        <div>
          {
            props.displayTopicAndHour ? (
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "250px", margin: "0 auto"}}>
                <div style={{backgroundColor: "#1100a2", color: "white", borderRadius: "10px ", padding: "3px 13px", marginTop: "7px" }}>{props.template?.topic}</div>
                <div style={{backgroundColor: "#1100a2", color: "white", borderRadius: "10px", padding: "3px 13px", marginTop: "7px" }}>{props.template?.hours.nbHours}h</div>
              </div>
            ) : null
          }
        </div>
      </Box>
  );
}
