import React from "react";
import "./FilAriane.css";
import CustomArrowIcon from "./ArrowForwardIcon";
import { ProgramStatus } from "../../models/Program.model";

export enum Page {
    ChoixDuModele = "Choix du modèle",
    EditionDuProgramme = "Édition du programme",
    Validation = "Validation du programme",
}

export function getPageFromProgramStatus(status: ProgramStatus): Page {
    console.log(status);
    switch (status) {
        case ProgramStatus.NOT_STARTED:
            return Page.ChoixDuModele;
        case ProgramStatus.EDITION:
            return Page.EditionDuProgramme;
        default:
            return Page.Validation;
    }
}

export function FilAriane(props: {
    CurrentPage: Page,
    style?: object,
}) {

    return (
    <React.Fragment>
        <div
            className={"filAriane"}
            style={props.style ? { ...props.style } : {}}
        >
            {Object.values(Page).map((page, index) => {
                return (
                    <React.Fragment key={index}>
                        {index > 0 &&
                            <CustomArrowIcon className="filAriane_separator" style={{ height: '100%' }} />
                        }
                        <div className={`filAriane_page ${page === props.CurrentPage ? "filAriane_page--current" : ""}`}>
                            {page}
                        </div>
                    </React.Fragment>
                )
            })}
        </div>
    </React.Fragment>
    );
}