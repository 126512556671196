import { ProgramModel, OPCO } from "../../../models/Program.model";
import Headers from "../../../Component/Headers/Headers";
import { Box, FormControlLabel, Grid, Stack, Switch } from "@mui/material";
import SuggestionField from "../../../Component/SuggestionField/SuggestionField";
import { ActionButton } from "../../../Component/ActionButton/ActionButton";
import SaveIcon from "@mui/icons-material/Save";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import { ProgramService } from "../../../service/program/Program.service";
import { HoursTextField } from "../../../Component/HoursTextField/HoursTextField";
import { Status } from "../../../Component/Status/Status";
import { Program } from "../../../Component/Program/Program";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { PdfService } from "../../../service/pdf/Pdf.service";
import { DisplayHourContext } from "../../../context/displayleHour.context";
import { NotificationType, notify } from "../../../utils/toaster";
import { toast } from "react-toastify";

export default function ProgramInEditionTrainer(props: {
  program: ProgramModel;
  setProgram: Dispatch<SetStateAction<ProgramModel | null>>;
  setValidated: Dispatch<SetStateAction<boolean>>;
  //openSuggestion: (flag:boolean) => void
}) {
  const [openSuggestion, setOpenSuggestion] = React.useState(true);
  const [displayHours, setDisplayHours] = useState(
    props.program.opco === OPCO.AKTO ? true : false
  );

  useEffect(() => {    
    //props.openSuggestion(openSuggestion)
  }, [openSuggestion])
  

  const createPDF = () => {
    ProgramService.getProgram().then((returnProgram: ProgramModel) => {
      PdfService.generatePdf(returnProgram).save(returnProgram.title + ".pdf");
    });
  };

  return (
    <DisplayHourContext.Provider value={[displayHours, setDisplayHours]}>
      <div>
        <div>
          <Headers showSave={true} status={props.program.status}/>
          <h1  style={{
            marginLeft: (openSuggestion ? "350px" : "0"),
            marginTop: "130px",
            textAlign: "center",
            padding: "0",
            }}>Édition de programme</h1>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <SuggestionField
              open={openSuggestion}
              setOpen={setOpenSuggestion}
            />
            <Box sx={{ width: "100%" }}>
              {/*  ---- TOP BUTTONS ----  */}
              <Grid container alignItems="center">
                {/* ---- STATUS AND NB HOURS OF THE PROGRAM ---- */}
                <Stack direction="row" alignItems="center" spacing={2}>
                  {props.program.opco === OPCO.AKTO ? (
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked={true}
                          value={!displayHours}
                          // /* ---- ACTIVER CE SWITCH SI ON DANS L'OBJECT IL YA L'OPCO AKTO  ---- */}
                          onChange={() => {
                            setDisplayHours(!displayHours);
                          }}
                        ></Switch>
                      }
                      label={'Afficher les heures'}
                    />
                  ) : null}
                  <HoursTextField hours={props.program.hours} />
                  <Status status={props.program.status} />
                </Stack>
              
                <Grid item xs />
                <ActionButton
                  label={"validate"}
                  text={"Valider"}
                  style={{paddingRight: "125px"}}
                  icon={RadioButtonUncheckedIcon}
                  onClick={() => {
                    ProgramService.validateProgram()
                      .then((program) => {
                        props.setProgram(program);
                        props.setValidated(true);
                        notify(NotificationType.SUCCESS, "Programme validé");
                      })
                      .catch((error) => {
                        notify(NotificationType.ERROR, error.message);
                      });
                  }}
                  disable={false}
                />
              </Grid>
              {/* ---- PROGRAM ---- */}
              <Program program={props.program} setProgram={props.setProgram} />
              {/*  ---- BOTTOM BUTTONS ----  */}
              <Stack direction="row">
          
                <ActionButton
                  label={"download"}
                  text={"Télécharger (PDF)"}
                  icon={FileDownloadIcon}
                  onClick={createPDF}
                  disable={false}
                />
              </Stack>
            </Box>
          </Box>
        </div>
      </div>
    </DisplayHourContext.Provider>
  );
}
