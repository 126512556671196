import { TurnedInNot } from "@mui/icons-material";
import {
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import ParticipantToolTip from "../ParticipantToolTip/ParticipantToolTip";
import React, { useState } from "react";
import DialogAddTrainer from "../DialogAddTrainer/DialogAddTrainer";
import { ProgramStatus } from "../../models/Program.model";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export function Status(props: { status: any }) {
  const [click, setClick] = useState(false);

  return (
    <HtmlTooltip
      title={<ParticipantToolTip click={click} setClick={setClick} />}
    >
      <Box
        sx={{
          color: "secondary.dark",
        }}
      >
        {/* Status of the document */}
        <div
          style={{
            padding: "16px",
          }}
        >
          <TurnedInNot
            sx={{
              display: "inline-flex",
              verticalAlign: "top",
            }}
          />{" "}
          Statut :
          {
            props.status===ProgramStatus.NOT_STARTED ?
            " En attente de sélection d'un modèle" :
            props.status===ProgramStatus.EDITION ?
            " En cours de rédaction" :
            props.status===ProgramStatus.MODERATION ?
            " En cours de validation" :
            props.status===ProgramStatus.CLIENT_VALIDATION ?
            " En attente de validation par le client" :
            props.status===ProgramStatus.ADMIN_VALIDATION ?
            " En attente de validation par Matchers" :
            props.status===ProgramStatus.VALIDATED ?
            " Finalisé" :
            " ..."
          }
        </div>
        <DialogAddTrainer open={click} setOpen={setClick} />
      </Box>
    </HtmlTooltip>
  );
}
