import { Box, Button, Grid, List, ListItem, Switch, styled } from '@mui/material';
import { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { SuggestionCard } from '../SuggestionCard/SuggestionCard';
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { ModuleSuggestionInterface } from '../../models/IModuleSuggestion.model';
import { SuggestionService, SuggestionType } from '../../service/suggestion/Suggestion.service';
import "./BiblioSuggestions.css";
import { GoalsContext, ModulesContext, SkillsContext } from '../../context/element.context';
import fuzzysort from 'fuzzysort'
import { ModuleModel } from '../../models/Module.model';
import { ElementModel } from '../../models/Element.model';
import Params from '../../params';
import { SearchBar } from '../SearchBar/SearchBar';


const NoContent = styled(Box)({
  textAlign: "center",
  padding: "20px",
});


enum TabSelected {
  Objectifs = 0,
  Modules = 1,
  Competences = 2
}

export default function BiblioSuggestions(

) {
  const [suggestions, setSuggestions] = useState<ModuleSuggestionInterface[]>([]);
  
  const [suggestionsBackup, setSuggestionsBackup] = useState<ModuleSuggestionInterface[]>([]);

  const [tabSelected, setTabSelected] = useState<TabSelected>(TabSelected.Objectifs);
  const [search, setSearch] = useState<string>("");

  //Récupération des modules (a partir des modules il est possibles de recuperer les compétences) et des objectifs
  const [modules,] = useContext(ModulesContext);
  const [goals,] = useContext(GoalsContext);
  const [skills,] = useContext(SkillsContext);

  const [listHeight, setListHeight] = useState<number>(0);

  const [onlyHistoric, setOnlyHistoric] = useState<boolean>(false);

  /* compute the suggestion list height for scrollbar */
  const computedListHeight = () =>{
    let suggestionHeader:HTMLElement|null = document.getElementById('matchers-suggestion-header')
    let drawer:number = window.innerHeight - 101 /* - 30; */
    let suggestionListHeight:number = 0;

    if(drawer && suggestionHeader){
      suggestionListHeight = drawer - suggestionHeader?.clientHeight /* - 90 + 30   */    
      setListHeight(suggestionListHeight)
    }   
  }
  
  /* list for window resize */
  useLayoutEffect(() => {
    
    function updateSize() {    
      computedListHeight()
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
 
  
 
 
  //Options for fuzzysort search
  let options = useMemo(() => {
    return {
      threshold: Params.precision_fuzzy_suggestion,
      limit: 15,
    }
  }, []);

  // search in suggestions loaded in memory
  useEffect(() => {    
    if (search?.length > 0) {
      let searchKey = tabSelected === TabSelected.Modules ? "title" : "text";
      let filtered: Fuzzysort.Results = fuzzysort.go(search, suggestionsBackup, { ...options, key: searchKey })
      setSuggestions(filtered.map((r: Fuzzysort.Result) => (r as any).obj))
    } else {
      // reset search and restore all possible data to search in
      setSuggestions(suggestionsBackup)
    }

  }, [search, suggestionsBackup])

  // get collection and type according selected tab
  const getExistantsToFilter = () => {
    let type: SuggestionType;
    var existantsElements: string[] = [];
    switch (tabSelected) {
      case TabSelected.Objectifs:
        type = SuggestionType.Goal;
        existantsElements = goals.map(goal => goal.text);
        break;
      case TabSelected.Modules:
        type = SuggestionType.Module;
        existantsElements = modules.map(module => module.title);
        break;
      case TabSelected.Competences:
        type = SuggestionType.Skill;
        existantsElements = skills.map(skill => skill.text);
        break;
    }

    return {type: type, existantsElements: existantsElements}
  }
  
  //TODO: Changer la gestion de l'erreur
  useEffect(() => {
    let info =  getExistantsToFilter()
    //Recuperation des suggestions par l'API en fonction du type de suggestion    
    SuggestionService.getSuggestions(info.type, true, onlyHistoric)
      .then((suggestions) => {
        setSuggestionsBackup(suggestions)
        setSuggestions(suggestions)
      })
      .catch((err) => console.error(err));
  }, [tabSelected, onlyHistoric, goals, modules, options, skills]);

  return (
    <Box style={{ backgroundColor: "primary.main!important"   }}>
      <div id="matchers-suggestion-header" style={{ borderRadius: 30,  backgroundColor: "primary.main!important", position: "relative", zIndex: 9999, overflowX: "hidden" }}>
        
        <div   style={{ }} className={"biblio_title"}>
          La Bibliothèque Matchers
        </div>

        <div style={{backgroundColor: "primary.main" }}>
          <Switch
            checked={onlyHistoric}
            onChange={() => setOnlyHistoric(!onlyHistoric)}
            sx={{ color: "white" }}
            color="secondary"
          />
          <span>Seulement mon historique</span>
        </div>

        {/* ----- SUGGESTIONS ----- */}
        {/*  Differentes pages (modules, competences et objectifs) -- HEADERS */}
        <Grid container sx={{ width: "100%", alignContent: "center", textAlign: "center", color: "white", paddingTop: "1.5em", backgroundColor: "primary.main" }}>
          <Grid item xs={4}>
            <Button
              className={"tabItem"} sx={{ color: "white", backgroundColor: (tabSelected === TabSelected.Objectifs ? "secondary.dark" : "primary.main") }}
              onClick={() => setTabSelected(TabSelected.Objectifs)}>
              <TrackChangesIcon fontSize="large" />
              Objectifs
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              className={"tabItem"} sx={{ color: "white", backgroundColor: (tabSelected === TabSelected.Modules ? "secondary.dark" : "primary.main") }}
              onClick={() => setTabSelected(TabSelected.Modules)}>
              <AppsIcon fontSize="large" />
              Modules
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              className={"tabItem"} sx={{ color: "white", backgroundColor: (tabSelected === TabSelected.Competences ? "secondary.dark" : "primary.main") }}
              onClick={() => setTabSelected(TabSelected.Competences)}>
              <SettingsSuggestIcon fontSize="large" />
              Compétences
            </Button>
          </Grid>
        </Grid>



        <SearchBar search={search} setSearch={setSearch} />

      </div>

      {/* HEHRE SCROLLABLE */}
      <div  style={{overflowX: "hidden", height: listHeight, scrollbarWidth: "thin"  }}>

        {/* Liste des suggestions */}
        <List className='thin-scrollbar' style={{ marginTop: 0, backgroundColor: "primary.main" }}>
          {suggestions.length === 0 ? (
            <NoContent color="white">
              <div className="display-inlineflex">
                <SentimentVeryDissatisfiedIcon sx={{ marginRight: "10px" }} />
                Pas de contenu suggérable
              </div>
            </NoContent>)
            :
            (suggestions.map((suggestion, index) => (
              <ListItem key={index} sx={{ display: "block" }}>
                <SuggestionCard
                  element={suggestion}
                  suggestionList={suggestions}
                  setSuggestionList={setSuggestions}
                  index={index}
                />
              </ListItem>
            ))
            )}
        </List>
      </div>
    </Box>
  )
}