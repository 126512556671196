import React from "react";
import { createRoot } from "react-dom/client";
import "reflect-metadata";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { themeMatchersLight } from "./theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ProviderProgram from "./ProviderProgram";
import Error404Page from "./page/page404/error404";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={themeMatchersLight}>
          <Routes>
            <Route path="/:token" element={<ProviderProgram />} />
            <Route path="*" element={<Error404Page />} />
          </Routes>
          <ToastContainer />
        </ThemeProvider>
      </DndProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
