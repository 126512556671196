import "./ElementTextField.css";
import React, { Dispatch, SetStateAction, useContext, useRef, useState } from "react";
import { ElementModel, ElementType } from "../../models/Element.model";
import { SkillSelected, TypeContext } from "../../context/type.context";
import { ElementService } from "../../service/program/Element.service";
import { notify, NotificationType } from "../../utils/toaster";
import { SkillsContext } from "../../context/element.context";


export function ElementTextField(props: {
  placeholder: string;
  size: number;
  index: number;
  element: ElementModel;
  content: ElementModel[];
  setContent: Dispatch<SetStateAction<ElementModel[]>>;
  parentModule?: string;
  swapObject: (item: ElementModel, item2: ElementModel) => void;
  onEnter: () => void;
}) {
  const [text, setText] = useState(props.element.text);

  //Pour pouvoir gérer les suggestions
  const [skills, setSkills] = useContext(SkillsContext)

  const textInput = useRef<HTMLInputElement>(null);

  const [, setSelectedType] = useContext(TypeContext);
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setText(event.currentTarget.value);
  };

  const ErrorDisplay = (err: any) => {
    switch (err.status) {
      case 500:
        notify(NotificationType.ERROR, err.data.error || err.statusText);
        break;
      case 400:
        notify(NotificationType.ERROR, err.data.error || err.statusText);
        break;
      default:
        notify(NotificationType.ERROR, err.statusText);
    }
  };

  const handleElementChange = (event: React.FormEvent<HTMLInputElement>) => {
    let previousText = props.element.text;
    setText(event.currentTarget.value);
    if (event.currentTarget.value) {
      if (props.element._id.length > 5) {

        // ever stored in DB
        switch (props.element.type) {
          case ElementType.GOAL:
            ElementService.updateGoal(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.MODALITY:
            ElementService.updateModality(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.PREREQUISITE:
            ElementService.updatePrerequisite(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.PUBLIC:
            ElementService.updatePublic(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.ASSET:
            ElementService.updateAsset(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.TERMSANDDEADLINEFORACCESS:
            ElementService.updateTermsAndDeadlineForAccess(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.DISABILITYACCESSIBILITY:
            ElementService.updateDisabilityAccessibility(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.EVALUATION:
            ElementService.updateEvaluation(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.SKILL:
            ElementService.updateSkill(
              props.element._id,
              event.currentTarget.value
            )
            .then((updatedSkill:ElementModel) => {
              
              let otherSkillIndex = props.content.findIndex((skill) => {
                return (skill._id === props.element._id)                   
              });

              props.content[otherSkillIndex] = updatedSkill
              let refreshedSkills = props.content
              props.setContent(refreshedSkills)
            })
            .catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
        }
      } else {

        // not yet stored in DB

        switch (props.element.type) {
          //TODO: Refactor
          case ElementType.GOAL:
            ElementService.addGoal(event.currentTarget.value);
            break;
          case ElementType.MODALITY:
            ElementService.addModality(event.currentTarget.value);
            break;
          case ElementType.PREREQUISITE:
            ElementService.addPrerequisite(event.currentTarget.value);
            break;
          case ElementType.PUBLIC:
            ElementService.addPublic(event.currentTarget.value);
            break;
          case ElementType.ASSET:
            ElementService.addAsset(event.currentTarget.value);
            break;
          case ElementType.TERMSANDDEADLINEFORACCESS:
            ElementService.addTermsAndDeadlineForAccess(event.currentTarget.value);
            break;
          case ElementType.DISABILITYACCESSIBILITY:
            ElementService.addDisabilityAccessibility(event.currentTarget.value);
            break;
          case ElementType.EVALUATION:
            ElementService.addEvaluation(event.currentTarget.value);
            break;
          case ElementType.SKILL:
            if (props.parentModule) {
              ElementService.addSkill(
                props.parentModule,
                event.currentTarget.value
              )
              .then((newSkill:ElementModel) => {
                // exclude the temp id and add the created one
                let filtered = props.content.filter((d:ElementModel) => d._id !=  props.element._id  )
                props.setContent(filtered.concat(newSkill))
              } );
            }
            break;
        }
      }
    }
  };
  const handleFocus = () => {
    if (props.element.type === ElementType.SKILL) {
      if (props.parentModule) {
        setSelectedType(
          new SkillSelected(props.parentModule, props.content, props.setContent)
        );
      } else {
        console.log("No parents module");
      }
    } else {
      setSelectedType(props.element.type);
    }
  };
  /* TODO: Reusable code for drag and drop of skill between module
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: props.element.getType(),
      item: props.element,
      options: {
        dropEffect: "copy",
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (!didDrop) {
        }
      },
    }),
    [props.element, props.swapObject]
  );
  const [, drop] = useDrop(
    () => ({
      accept: props.element.getType(),
      hover(item: ElementModel, monitor) {
        if (item._id !== props.element._id) {
        }
      },
    }),
    [props.swapObject]
  );
  */

  React.useEffect(() => {
    if((props.index === props.content.length - 1) && text === ""){
      textInput.current?.focus();
    }
  }, [text, props.index, props.content.length]);

  React.useEffect(() => {
    if(document.activeElement !== textInput.current && textInput.current?.value === ""){
      props.setContent((previousValue) => {
        return previousValue.filter((element) => {
          return element._id !== props.element._id;
        }
        );
      });
    }
  }, [props]);

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (text !== "") {
        props.onEnter();
      } else {
        props.setContent((previousValue) => {
          return previousValue.filter((element) => {
            return element._id !== props.element._id;
          }
          );
        });
      }
      e.currentTarget.blur();
    }
  };

  return (
    <React.Fragment>
      <input
        type="text"
        ref={textInput}
        className="textfield"
        placeholder={props.placeholder}
        id={props.element._id}
        value={text}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleElementChange}
        onKeyDown={(e) => handleEnter(e)}
      />
    </React.Fragment>
  );
}
