import axios from "axios";
import { plainToInstance } from "class-transformer";
import { ModuleModel, ModuleOrigin } from "../../models/Module.model";

export class ModuleService {
  public static updateModuleTitle(
    id: string,
    title: string
  ): Promise<ModuleModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/updateModule",
          { moduleId: id, title: title }
        )
        .then((response) => {
          let moduleJson = response.data;
          let module = plainToInstance(ModuleModel, moduleJson);
          // @ts-ignore
          resolve(module);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
  public static updateModuleDuration(
    id: string,
    duration: number
  ): Promise<ModuleModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL +
            "/training-program/updateModuleDuration",
          { moduleId: id, duration: duration }
        )
        .then((response) => {
          let moduleJson = response.data.module;
          let module = plainToInstance(ModuleModel, moduleJson);
          // @ts-ignore
          resolve(module);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
  public static deleteModuleOfTrainingProgram(
    id: string
  ): Promise<ModuleModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.REACT_APP_API_URL + "/training-program/deleteModule", { 
          data: { moduleId: id }
        }
        )
        .then((response) => {
          let modulesJson = response.data.modules;
          let modules = plainToInstance(ModuleModel, modulesJson);
          resolve(modules);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
  public static deleteModuleOfModule(id: string): Promise<ModuleModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL +
            "/training-program/module/deleteModule",
          { moduleId: id }
        )
        .then((response) => {
          let modulesJson = response.data.modules;
          let modules = plainToInstance(ModuleModel, modulesJson);
          resolve(modules);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
  public static addModuleInTrainingProgram(
    title: string
  ): Promise<ModuleModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/addModule", {
          title: title,
        })
        .then((response) => {
          let moduleJson = response.data;
          let module = plainToInstance(ModuleModel, moduleJson);
          // @ts-ignore
          resolve(module);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
  public static addModuleInTrainingProgramFromSuggestion(
    id: string,
    origin: ModuleOrigin
  ): Promise<ModuleModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL +
            "/training-program/addModuleFromSuggestion",
          { id: id, origin: origin }
        )
        .then((response) => {
          let moduleJson = response.data;
          let module = plainToInstance(ModuleModel, moduleJson);
          // @ts-ignore
          resolve(module);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  public static addModuleInModuleFromSuggestion(
    moduleSuggestionId: string,
    moduleId: string,
    origin:ModuleOrigin
  ): Promise<ModuleModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL +
            "/training-program/module/addModuleFromSuggestion",
          { id: moduleSuggestionId, moduleId: moduleId, origin: origin }
        )
        .then((response) => {
          console.log(response.data.module);
          let moduleJson = response.data;
          let module = plainToInstance(ModuleModel, moduleJson);
          // @ts-ignore
          resolve(module);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
  public static addModuleInModule(
    id: string,
    title: string
  ): Promise<ModuleModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/module/addModule",
          { moduleId: id, title: title }
        )
        .then((response) => {
          let moduleJson = response.data;
          let module = plainToInstance(ModuleModel, moduleJson);
          // @ts-ignore
          resolve(module);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
  public static updateModuleOrderInModule(
    modules: ModuleModel[],
    parentModuleId: string
  ): Promise<ModuleModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL +
            "/training-program/module/updateModulesOrder",
          { modules: modules, moduleId: parentModuleId }
        )
        .then((response) => {
          let modulesJson = response.data.modules;
          let modules = plainToInstance(ModuleModel, modulesJson);
          resolve(modules);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
  public static updateModuleOrderInProgram(
    modules: ModuleModel[]
  ): Promise<ModuleModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL +
            "/training-program/updateModulesOrder",
          { modules: modules }
        )
        .then((response) => {
          let modulesJson = response.data.modules;
          let modules = plainToInstance(ModuleModel, modulesJson);
          resolve(modules);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
}
