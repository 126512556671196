import { useEffect, useState } from "react";
import { ElementModel, ElementType } from "./models/Element.model";
import {
  ModuleSelected,
  SkillSelected,
  TypeContext,
} from "./context/type.context";
import { ProgramModel, ProgramStatus } from "./models/Program.model";
import { User, UserContext } from "./context/user.context";
import { useNavigate, useParams } from "react-router-dom";
import { AuthInterceptor } from "./middleware/Interceptor/Auth.interceptor";
import { ProgramService } from "./service/program/Program.service";
import { ProgramContext } from "./context/program.context";
import {
  AssetsContext,
  TermsAndDeadlineForAccessContext,
  DisabilityAccessibilityContext,
  EvaluationContext,
  GoalsContext,
  ModalitiesContext,
  ModulesContext,
  PrerequisitesContext,
  PublicsContext,
  SkillsContext
} from "./context/element.context";
import { ModuleModel } from "./models/Module.model";
import { UserService } from "./service/user/User.service";
import App from "./App";
import { NotificationType, notify } from "./utils/toaster";
import DevDebugTools from "./Component/Debug/DebDebugTools/DevDebugTools";
import { isProduction } from "./utils/runtime";
import { GlobalDrag, GlobalDragContext } from "./context/globalDrag.context";
import { Debug, DebugContext } from "./context/debugContext";

export default function ProviderProgram() {
  let { token } = useParams();
  AuthInterceptor(token!);
  // program
  const [program, setProgram] = useState<ProgramModel | null>(null);
  const [selectedType, setSelectedType] = useState<
    ElementType | ModuleSelected | SkillSelected
  >(ElementType.GOAL);

  const [goals, setGoals] = useState<ElementModel[]>([]);
  const [modalities, setModalities] = useState<ElementModel[]>([]);
  const [publics, setPublics] = useState<ElementModel[]>([]);
  const [prerequisites, setPrerequisites] = useState<ElementModel[]>([]);
  const [evaluation, setEvaluation] = useState<ElementModel[]>([]);
  const [assets, setAssets] = useState<ElementModel[]>([]);
  const [termsAndDeadlineForAccess, setTermsAndDeadlineForAccess] = useState<ElementModel[]>([]);
  const [disabilityAccessibility, setDisabilityAccessibility] = useState<ElementModel[]>([]);
  const [modules, setModules] = useState<ModuleModel[]>([]);
  const [skills, setSkills] = useState<ElementModel[]>([]);

  /* to know dragging global states : should use monitor listeners instead (like in App.tsx) */
  const [globalDrag, setGlobalDrag] = useState<GlobalDrag>(new GlobalDrag());
  
  const [debug, setDebug] = useState<Debug>(new Debug({
    displayDebugTool:false,
    debug:false,
    debugDom:false
  }));
  
  let navigate = useNavigate();

  useEffect(() => {
    
    ProgramService.getProgram()
      .then((returnProgram: ProgramModel) => {
        setProgram(returnProgram);
        setGoals(returnProgram.goals);
        setModalities(returnProgram.modalities);
        setPublics(returnProgram.publics);
        setPrerequisites(returnProgram.prerequisites);
        setEvaluation(returnProgram.evaluation);
        setAssets(returnProgram.assets);
        setTermsAndDeadlineForAccess(returnProgram.termsAndDeadlineForAccess);
        setDisabilityAccessibility(returnProgram.disabilityAccessibility);
        setModules(returnProgram.modules);
        setLoading(false);
        setDisplayProgram(returnProgram.status !== ProgramStatus.NOT_STARTED);
        
        //We get all the skills existing in the program for each module
        var skills: ElementModel[] = [];
        returnProgram.modules.forEach((module) => {
          skills = skills.concat(module.skills);
        });
        setSkills(skills);
      })
      .catch((e) => {
        notify(NotificationType.ERROR, "Une erreur est survenue lors du chargement du programme");
        setLoading(false);
        setDisplayProgram(false);
        navigate("/", { replace: true });
      });
  }, [navigate]);

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    UserService.getUser().then((returnUser) => {
      setUser(returnUser);
    });
  }, []);

  const [loading, setLoading] = useState(true);
  const [displayProgram, setDisplayProgram] = useState<boolean | null>(null);

  return (
    <DebugContext.Provider value={[debug, setDebug]}>
    <ProgramContext.Provider value={[program, setProgram]}>
      <TypeContext.Provider value={[selectedType, setSelectedType]}>
        <GoalsContext.Provider value={[goals, setGoals]}>
          <ModalitiesContext.Provider value={[modalities, setModalities]}>
            <PublicsContext.Provider value={[publics, setPublics]}>
              <PrerequisitesContext.Provider
                value={[prerequisites, setPrerequisites]}
              >
                <EvaluationContext.Provider value={[evaluation, setEvaluation]}>
                  <AssetsContext.Provider value={[assets, setAssets]}>
                    <TermsAndDeadlineForAccessContext.Provider value={[termsAndDeadlineForAccess, setTermsAndDeadlineForAccess]}>
                      <DisabilityAccessibilityContext.Provider value={[disabilityAccessibility, setDisabilityAccessibility]}>
                        <ModulesContext.Provider value={[modules, setModules]}>
                          <UserContext.Provider value={[user, setUser]}>
                            <SkillsContext.Provider value={[skills, setSkills]}>

                              <GlobalDragContext.Provider value={[globalDrag, setGlobalDrag]}>
                                <DevDebugTools></DevDebugTools>

                              <App
                                displayProgram={displayProgram}
                                loading={loading}
                                setDisplayProgram={setDisplayProgram}
                                setLoading={setLoading}
                              />
                              </GlobalDragContext.Provider>


                            </SkillsContext.Provider>
                          </UserContext.Provider>
                        </ModulesContext.Provider>
                      </DisabilityAccessibilityContext.Provider>
                    </TermsAndDeadlineForAccessContext.Provider>
                  </AssetsContext.Provider>
                </EvaluationContext.Provider>
              </PrerequisitesContext.Provider>
            </PublicsContext.Provider>
          </ModalitiesContext.Provider>
        </GoalsContext.Provider>
      </TypeContext.Provider>
    </ProgramContext.Provider>
    </DebugContext.Provider>

  );
}
