import { jsPDF } from "jspdf";
import { ProgramModel } from "../../models/Program.model";
import { ModuleModel } from "../../models/Module.model";
import { ElementModel } from "../../models/Element.model";
import "../../assets/Poppins-Regular-normal";
import logo from "../../assets/nouveau_logo.png";
export class PdfService {
  public static generatePdf(program: ProgramModel) {
    const doc = new jsPDF();

    let height: number = 30;
    doc.setFontSize(20);
    doc.setFont("Poppins");
    doc.text(program.title, 105, height, { align: "center", maxWidth: 170 });
    height += doc.getTextDimensions(program.title, { maxWidth: 170 }).h + 10;
    doc.text(program.topic, 105, height, { align: "center", maxWidth: 170 });
    height += doc.getTextDimensions(program.topic, { maxWidth: 170 }).h + 10;
    height = this.printSection(doc, program.goals, 20, height, "OBJECTIFS :");
    height += 10;
    height = this.printModules(doc, program, height);

    height = this.printSection(
      doc,
      program.modalities,
      20,
      height,
      "MODALITÉS PÉDAGOGIQUES :"
    );
    height += 10;
    height = this.printSection(doc, program.publics, 20, height, "PUBLICS :");
    height += 10;
    height = this.printSection(
      doc,
      program.prerequisites,
      20,
      height,
      "PRÉREQUIS :"
    );
    height += 10;
    height = this.printSection(
      doc,
      program.evaluation,
      20,
      height,
      "SUIVI ET ÉVALUATION :"
    );
    height += 10;
    height = this.printSection(doc, program.assets, 20, height, "LES ATOUTS :");
    height += 10;
    height = this.printSection(doc, program.termsAndDeadlineForAccess, 20, height, "MODALITES ET DELAI D'ACCÈS :");
    height += 10;
    height = this.printSection(doc, program.disabilityAccessibility, 20, height, "ACCESSIBILITÉ HANDICAP :");
    this.setFooter(doc);
    this.setHeader(doc);

    return doc;
  }
  public static getSizeOfSection(
    doc: jsPDF,
    array: string[],
    option?: {
      font?: string | undefined;
      fontSize?: number | undefined;
      maxWidth?: number | undefined;
      scaleFactor?: number | undefined;
    }
  ) {
    let size = 0;
    array.map((item) => {
      let res = doc.getTextDimensions(item, option);
      size += res.h;
      return size;
    });
    return size;
  }
  public static printSection(
    doc: jsPDF,
    section: ElementModel[],
    width: number,
    height: number,
    title: string
  ) {
    let arrayElement = section.map((element) => {
      return "• " + element.toString();
    });
    if (
      PdfService.getSizeOfSection(doc, arrayElement, {
        maxWidth: 180 - width,
      }) +
        height +
        2 +
        doc.getTextDimensions(title, { maxWidth: 190 - width }).h <
      267
    ) {
      this.setTitle(doc);
      doc.text(title, width, height, { maxWidth: 190 - width });
      height += doc.getTextDimensions(title, { maxWidth: 190 - width }).h + 2;
      doc.setFontSize(10);
      doc.setFont("Poppins");
      doc.text(arrayElement, width + 10, height, { maxWidth: 180 - width });
    } else {
      doc.addPage();
      height = 30;
      this.setTitle(doc);
      doc.text(title, width, height, { maxWidth: 190 - width });
      height += doc.getTextDimensions(title, { maxWidth: 190 - width }).h + 2;
      doc.setFont("Poppins");
      doc.setFontSize(10);
      doc.text(arrayElement, width + 10, height, { maxWidth: 180 - width });
    }

    return (
      height +
      PdfService.getSizeOfSection(doc, arrayElement, {
        maxWidth: 180 - width,
        fontSize: 10,
      })
    );
  }

  public static setHeader(doc: jsPDF) {
    for (let i = 0; i <= doc.getNumberOfPages(); i++) {
      doc.setPage(i);
      doc.addImage(logo, "PNG", 180, 7, 15, 15);
    }
  }
  public static setFooter(doc: jsPDF) {
    for (let i = 0; i <= doc.getNumberOfPages(); i++) {
      doc.setPage(i);
      console.log(doc.getFontSize());
      doc.setFontSize(8);
      doc.text("Pour toute correspondance :", 20, 277);
      doc.text(
        "MATCHERS – 120, Rue Adrien Proby – 34 090 Montpellier – contact@matchers.fr – 09 72 26 43 99",
        105,
        282,
        { align: "center" }
      );
      doc.setFontSize(6);
      doc.text(
        [
          "CMC INSPIRATION S.A.S. –Via Innova – ZAE Espace Lunel Littoral – 177b Av Louis Lumière 34 400 Lunel, France",
          "SAS au capital de 15 000€ – R.C.S. Montpellier – Siren 849533849 – TVA intra. FR85849533849 - APE 6201Z – N° DA : 76-34-10490-34",
        ],
        105,
        287,
        { align: "center" }
      );
    }
  }

  public static printModules(
    doc: jsPDF,
    program: ProgramModel,
    height: number
  ) {
    this.setTitle(doc);
    doc.text("CONTENU :", 20, height);
    height += 10;
    let modulesSort = program.modules.sort((a, b) => {
      return a.number - b.number;
    });
    modulesSort.map((module, index) => {
      if (module.modules.length > 0) {
        let arrayElement = module.modules[0].skills.map((element) => {
          return "• " + element.toString();
        });

        if (
          doc.getTextDimensions(
            "Module " + (index + 1) + " : " + module.title,
            { maxWidth: 160, fontSize: 14 }
          ).h +
            PdfService.getSizeOfSection(doc, arrayElement, {
              maxWidth: 140,
              fontSize: 10,
            }) +
            height +
            doc.getTextDimensions("1 - " + module.modules[0].title, {
              maxWidth: 150,
              fontSize: 14,
            }).h +
            20 <
          267
        ) {
          this.setTitle(doc);
          doc.text("Module " + (index + 1) + " : " + module.title, 30, height, {
            maxWidth: 160,
          });
          height +=
            doc.getTextDimensions(
              "Module " + (index + 1) + " : " + module.title,
              { maxWidth: 160, fontSize: 14 }
            ).h + 2;
          height = this.printSubModules(doc, module, height);
          height += 10;
        } else {
          doc.addPage();
          height = 30;
          this.setTitle(doc);
          doc.text("Module " + (index + 1) + " : " + module.title, 30, height, {
            maxWidth: 160,
          });
          height +=
            doc.getTextDimensions(
              "Module " + (index + 1) + " : " + module.title,
              { maxWidth: 160, fontSize: 14 }
            ).h + 2;
          height = this.printSubModules(doc, module, height);
          height += 10;
        }
      } else {
        height = this.printSection(
          doc,
          module.skills,
          30,
          height,
          "Module " + (index + 1) + " : " + module.title
        );
        height += 10;
      }
      return height;
    });
    return height;
  }

  public static printSubModules(
    doc: jsPDF,
    module: ModuleModel,
    height: number
  ) {
    let modulesSort = module.modules.sort((a, b) => {
      return a.number - b.number;
    });
    modulesSort.map((subModule, index) => {
      height = this.printSection(
        doc,
        subModule.skills,
        40,
        height,
        index + 1 + " - " + subModule.title
      );
      height += 10;
      return height;
    });

    return height;
  }
  public static setTitle(doc: jsPDF) {
    doc.setFontSize(14);
    doc.setFont("Poppins");
    return doc;
  }
}
