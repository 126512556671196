import { ElementModel, ElementType } from "./Element.model";
import { TrainerModel } from "./Trainer.model";
import { ModuleSuggestionInterface } from "./IModuleSuggestion.model";
import { Type } from "class-transformer";

export enum ModuleDeep {
  ONE = "Module",
  TWO = "Module à 2 niveaux",
}

/*
  used when dropping module from suggestion to program
  we need to inform backend if the module should be get in moduleSuggestions collection or modules collection
*/
export enum ModuleOrigin{
  SUGGESTION = "SUGGESTION",
  HISTORIC = "HISTORIC",
  PROGRAM = "PROGRAM"
}

export class ModuleModel implements ModuleSuggestionInterface {
  public _id: string;
  public title: string;
  @Type(() => ModuleModel)
  public modules: ModuleModel[];
  @Type(() => ElementModel)
  public skills: ElementModel[];
  @Type(() => TrainerModel)
  public writer: TrainerModel | null;
  public model?: string;
  public duration: number | null;
  public number: number;

  // UI internal states
  public tmpId?:string;       // used for internal fake id when creating a module we wait for user input title to save in db
  public shouldFocus:boolean  // used to focus the input on the module
  public origin:ModuleOrigin; // to know if module come from historic or suggestion

  constructor(
    _id: string,
    title: string,
    modules: ModuleModel[],
    skills: ElementModel[],
    writer: TrainerModel | null = null,
    duration: number | null = null,
    number: number,
    model?: string
  ) {
    this._id = _id;
    this.title = title;
    this.modules = modules;
    this.skills = skills;
    this.writer = writer;
    this.model = model;
    this.duration = duration;
    this.number = number;

    // UI states
    this.origin = ModuleOrigin.PROGRAM;
    this.shouldFocus = false;
    this.tmpId = undefined; // when module in not yet saved in DB
  }

  setTmpId(id:string){
    this.tmpId = id;
  }

  getClone(){
    let s = this;
    return new ModuleModel(s._id,s.title, s.modules, s.skills, s.writer, s.duration, s.number, s.model)
  }

  search(search: string): boolean {
    if (this.skills) {
      return (
        this.title.toLowerCase().includes(search.toLowerCase()) ||
        this.skills.some((skill) => skill.search(search))
      );
    } else {
      return this.title.toLowerCase().includes(search.toLowerCase());
    }
  }

  toString(): string {
    //TODO : return a description of the module
    return this.title;
  }

  getType(): ElementType {
    return ElementType.MODULE;
  }
  getModuleDeep(): ModuleDeep {
    if (this.modules && this.modules.length > 0) {
      return ModuleDeep.TWO;
    } else {
      return ModuleDeep.ONE;
    }
  }
}
